import {
  updateActiveAircraft,
  updateActiveFlight,
  updateAircraftList,
  updateConfiguration,
  updateFlightList,
} from 'actions';
import config from 'config';
import store from 'store';

export const EXECUTE_PERIODIC_CONFIGURATION_FETCH = 'EXECUTE_PERIODIC_CONFIGURATION_FETCH';
export const EXECUTE_PERIODIC_DATA_FETCH = 'EXECUTE_PERIODIC_DATA_FETCH';
export const INITIALIZE_APP = 'INITIALIZE_APP';
export const START_PERIODIC_DATA_FETCH = 'START_PERIODIC_DATA_FETCH';
export const STOP_PERIODIC_DATA_FETCH = 'STOP_PERIODIC_DATA_FETCH';
export const STOP_PERIODIC_CONFIGURATION_FETCH = 'STOP_PERIODIC_CONFIGURATION_FETCH';
export const TOGGLE_DEBUG = 'TOGGLE_DEBUG';
export const UNINITIALIZE_APP = 'UNINITIALIZE_APP';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';

let fetchConfigurationTimeout;
let fetchWaitTimeout;
const fiveMinInterval = (5 * 60 * 1000);

export function executeDataFetch() {
  return async (dispatch) => {
    const { configuration } = store.getState();
    let fetchData = !(configuration.maintenance_mode);

    if (fetchData) {
      // signal executing periodic aircraft search
      dispatch({
        type: EXECUTE_PERIODIC_DATA_FETCH,
      });

      clearTimeout(fetchWaitTimeout);

      dispatch(updateAircraftList());
      dispatch(updateFlightList());
      dispatch(updateActiveAircraft());
      dispatch(updateActiveFlight());
      dispatch(updateCurrentTime());
    }

    fetchWaitTimeout = setTimeout(() => dispatch(executeDataFetch()), config.app.refreshInterval);
  }
}

export function startPeriodicDataFetch() {
  return (dispatch) => {
    // signal starting periodic aircraft search
    dispatch({
      type: START_PERIODIC_DATA_FETCH,
    });

    dispatch(executeDataFetch());
  }
}

export function stopPeriodicDataFetch() {
  return (dispatch) => {
    // signal stopping periodic aircraft search
    dispatch({
      type: STOP_PERIODIC_DATA_FETCH,
    });

    clearInterval(fetchWaitTimeout);
  }
}

export const toggleDebug = ({
  type: TOGGLE_DEBUG,
});

export function updateCurrentTime() {
  return async (dispatch) => {
    const currentTime = Date.now();
    dispatch({
      type: UPDATE_CURRENT_TIME,
      currentTime
    });

    const nextMinuteOffset = (60 * 1000) - (currentTime % (60 * 1000));
    fetchWaitTimeout = setTimeout(() => dispatch(updateCurrentTime()), nextMinuteOffset);
  }
}

export const periodicConfigurationFetch = () => {
  return async (dispatch) => {
    dispatch({
      type: EXECUTE_PERIODIC_CONFIGURATION_FETCH,
    });

    const currentTime = Date.now();
    await dispatch(updateConfiguration());
    const fiveMinuteOffset = (fiveMinInterval) - (currentTime % (fiveMinInterval));
    fetchConfigurationTimeout = setTimeout(() => dispatch(periodicConfigurationFetch()), fiveMinuteOffset);
  }
}

export const stopPeriodicConfigurationFetch = () => {
  return (dispatch) => {
    dispatch({
      type: STOP_PERIODIC_CONFIGURATION_FETCH,
    });

    clearInterval(fetchConfigurationTimeout);
  }
}