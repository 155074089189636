// TODO: implement debounce

import { FlightFactory } from 'classes';
import store from 'store';

export const CLEAR_ACTIVE_FLIGHT = 'CLEAR_ACTIVE_FLIGHT';
export const FETCH_ACTIVE_FLIGHT = 'FETCH_ACTIVE_FLIGHT';
export const FETCH_FLIGHT_LIST = 'FETCH_FLIGHT_LIST';
export const SET_ACTIVE_FLIGHT = 'SET_ACTIVE_FLIGHT';
export const UPDATE_ACTIVE_FLIGHT = 'UPDATE_ACTIVE_FLIGHT';
export const UPDATE_FLIGHT_LIST = 'UPDATE_FLIGHT_LIST';

var activeFlightRequest = null;

export function clearActiveFlight() {
  return {
    type: CLEAR_ACTIVE_FLIGHT,
  }
}

export function setActiveFlight(flight) {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_FLIGHT,
      activeFlight: flight,
    });

    if (activeFlightRequest) {
      activeFlightRequest.cancel();
    }

    if (!flight) {
      return;
    }
    
    dispatch({
      type: FETCH_ACTIVE_FLIGHT,
    });

    const fid = flight.id;
    activeFlightRequest = FlightFactory.getFlightById(fid);
    activeFlightRequest.then((activeFlight) => {
      if (activeFlight) {
        dispatch({
          type: SET_ACTIVE_FLIGHT,
          activeFlight,
        });
      }

      activeFlightRequest = null;
    });
  }
}

export function updateActiveFlight() {
  return async (dispatch) => {
    const { activeFlight: currentFlight } = store.getState();
    // ignore request if no current active flight or if the current flight is closed
    if (!currentFlight || currentFlight.is_open === false) {
      return;
    }

    if (activeFlightRequest) {
      activeFlightRequest.cancel();
    }

    dispatch({
      type: FETCH_ACTIVE_FLIGHT,
    });

    const ftFetchDt = currentFlight.modified + 1000;
    const fid = currentFlight.id;
    activeFlightRequest = FlightFactory.getFlightById(fid, ftFetchDt);
    activeFlightRequest.then((activeFlight) => {
      if (activeFlight) {
        dispatch({
          type: UPDATE_ACTIVE_FLIGHT,
          activeFlight,
        });
      }

      activeFlightRequest = null;
    });
  };
}

export function updateFlightList(dt) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_FLIGHT_LIST,
    });

    const { flightList: currentFlightList } = store.getState();
    let ftListFetchDt;

    if (!dt) {
      currentFlightList?.forEach((flight) => {
        if (!ftListFetchDt || flight.modified > ftListFetchDt) {
          ftListFetchDt = flight.modified + 1000;
        }
      });
    }

    const flightList = await FlightFactory.getAllFlights(dt || ftListFetchDt);

    dispatch({
      type: UPDATE_FLIGHT_LIST,
      flightList,
    });
  }
}
