import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Icon } from 'assets/icons/FlightIcon.svg';

export const FlightIcon = ({ fill, height, rotate, width }) => {
  return (
    <Icon title="Flight" style={{
      fill,
      height,
      transform: `rotate(${rotate}deg)`,
      width,
    }} />
  );
};

FlightIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  rotate: PropTypes.string,
  width: PropTypes.string,
};
