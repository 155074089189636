import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import ButtonItem from 'components/inputs/ButtonItem';
import BarGraph from 'components/BarGraph';

import styles from './styles.module.css';

const FlightStatistics = (props) => {
  const {
    changeMode,
    changeLatest,
    changeStatType,
    currentMode,
    currentStatType,
    data,
    totalLRUs,
    totalITUs,
    isFlightOpen,
    isLatest,
  } = props;

  const renderHeader = (mode) => {
    switch (mode) {
      case 'seatReset':
        return (
          <span className={styles.headerValue}> ITU resets this flight </span>
        )
      case 'wap':
        return (
          isFlightOpen
            ? (
              <span>
                ITU WAPs :&nbsp;
                <span
                  data-cy-id="Flights-StatisticsPanel-LatestLink"
                  className={isLatest ? styles.headerValue : styles.headerLink}
                  onClick={() => {
                    if (!isLatest) {
                      changeLatest(true);
                    }
                  }}
                >
                  Latest
                </span>
                &nbsp;|&nbsp;
                <span
                  data-cy-id="Flights-StatisticsPanel-EntireFlightLink"
                  className={isLatest ? styles.headerLink : styles.headerValue}
                  onClick={() => {
                    if (isLatest) {
                      changeLatest(false);
                    }
                  }}
                >
                  Entire Flight
                </span>
              </span >
            )
            : (
              <span className={styles.headerValue}> ITUs WAPs this flight </span>
            )
        )
      case 'disconnected':
        return (
          isFlightOpen
            ? (
              <span>
                ITUs connected :&nbsp;
                <span
                  className={isLatest ? styles.headerValue : styles.headerLink}
                  onClick={() => {
                    if (!isLatest) {
                      changeLatest(true);
                    }
                  }}
                >
                  Latest
                </span>
                &nbsp;|&nbsp;
                <span
                  className={isLatest ? styles.headerLink : styles.headerValue}
                  onClick={() => {
                    if (isLatest) {
                      changeLatest(false);
                    }
                  }}
                >
                  Entire Flight
                </span>
              </span>
            )
            : (
              <span className={styles.headerValue}> ITUs connected this flight </span>
            )
        )
      case 'faults':
      default:
        return (
          <span className={styles.headerValue}> LRU faults this flight </span>
        )
    }
  }

  const idPrefix = 'FlightStatistics';
  const {
    singleFault,
    multipleFaults,
    hardReset,
    softReset,
    affectedReset,
    totalSecondaryWAPCount,
    currentSecondaryWAPCount,
    totalDisconnectedCount,
    currentDisconnectedCount,
  } = data.faultStats;

  return (
    <Col className={styles.root} data-cy-id="Flights-StatisticsPanel">
      <Row noGutters>
        <Col className={styles.header}>
          LOPA is displaying:&nbsp;
          {renderHeader(currentMode)}
        </Col>
      </Row>
      <Row noGutters>
        <Col md="auto" className={styles.buttonContainer}>
          <Button
            data-cy-id="Flights-StatisticsPanel-FaultsButton"
            className={classnames("button_reg", styles.button, currentMode === 'faults' ? styles.activeButton : null)}
            onClick={() => changeMode('faults')}
          >
            Faults
          </Button>
        </Col>
        <Col md="auto" className={styles.buttonContainer}>
          <Button
            data-cy-id="Flights-StatisticsPanel-ResetsButton"
            className={classnames("button_reg", styles.button, currentMode === 'seatReset' ? styles.activeButton : null)}
            onClick={() => changeMode('seatReset')}
          >
            Resets
          </Button>
        </Col>
        <Col md="auto" className={styles.buttonContainer}>
          <Button
            data-cy-id="Flights-StatisticsPanel-WAPsButton"
            className={classnames("button_reg", styles.button, currentMode === 'wap' ? styles.activeButton : null)}
            onClick={() => changeMode('wap')}
          >
            WAPs
          </Button>
        </Col>
        <Col md="auto" className={styles.buttonContainer}>
          <Button
            data-cy-id="Flights-StatisticsPanel-ConnectedButton"
            className={classnames("button_reg", styles.button, currentMode === 'disconnected' ? styles.activeButton : null)}
            onClick={() => changeMode('disconnected')}
          >
            Connected
          </Button>
        </Col>
      </Row>
      <Row noGutters className={styles.LRUCountContainer}>
        <Col className={styles.LRUCount}>
          {totalLRUs} LRUs / {totalITUs} Pax
        </Col>
        <Col className={styles.radioButtonContainer}>
          <ButtonItem
            label="Number"
            value="number"
            onChange={() => changeStatType('count')}
            type="radio"
            classname={styles.buttonItemLabel}
            isChecked={currentStatType === 'count'}
            idPrefix={`${idPrefix}-number`}
          />
          <ButtonItem
            label="Percent"
            value="percent"
            onChange={() => changeStatType('percentage')}
            type="radio"
            classname={styles.buttonItemLabel}
            isChecked={currentStatType === 'percentage'}
            idPrefix={`${idPrefix}-percent`}
          />
        </Col>
      </Row>
      <Row
        className={classnames(styles.detailContainer, currentMode === 'faults' ? styles.detailContainerActive : '')}
        onClick={() => changeMode('faults')}
        noGutters
      >
        <Col>
          <BarGraph
            title="1 fault"
            primaryFillColor="var(--CL_STATUS_DEGRADED)"
            primaryFillPercent={(singleFault * 100) / totalLRUs}
            primaryLabel={currentStatType === 'count'
              ? singleFault : (Math.round(singleFault * 100 / totalLRUs)).toString().concat('%')}
            containerClassName={styles.barContainer}
          />
          <BarGraph
            title="2+ faults"
            primaryFillColor="var(--CL_STATUS_UNHEALTHY)"
            primaryFillPercent={(multipleFaults * 100) / totalLRUs}
            primaryLabel={currentStatType === 'count'
              ? multipleFaults : (Math.round(multipleFaults * 100 / totalLRUs)).toString().concat('%')}
            containerClassName={styles.barContainer}
          />
        </Col>
      </Row>
      <Row
        className={classnames(styles.detailContainer, currentMode === 'seatReset' ? styles.detailContainerActive : '')}
        onClick={() => changeMode('seatReset')}
        noGutters
      >
        <Col>
          <Row className={styles.row}>
            <Col md={8}>
              Soft resets
            </Col>
            <Col md={2} className={classnames(styles.resetBlock, styles.barFillSoftReset)} />
            <Col md={2} className={styles.numbers}>
              {softReset}
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={8}>
              Hard resets
            </Col>
            <Col md={2} className={classnames(styles.resetBlock, styles.barFillHardReset)} />
            <Col md={2} className={styles.numbers}>
              {hardReset}
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={8}>
              Affected by hard reset
            </Col>
            <Col md={2} className={classnames(styles.resetBlock, styles.barFillAffectedReset)}>
              <div className={classnames(styles.resetBlock, styles.barFillAffectedResetDot)} />
            </Col>
            <Col md={2} className={styles.numbers}>
              {affectedReset}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className={classnames(styles.detailContainer, currentMode === 'wap' ? styles.detailContainerActive : '')}
        onClick={() => changeMode('wap')}
        noGutters
      >
        <Col>
          <BarGraph
            title="Secondary WAP"
            primaryFillColor="var(--CL_ORANGE_1)"
            secondaryFillColor={!isFlightOpen || currentMode === 'wap' ? null : "var(--CL_ORANGE_1_60)"}
            primaryFillPercent={
              currentMode === 'wap'
                ? isLatest ? (currentSecondaryWAPCount * 100) / totalLRUs : (totalSecondaryWAPCount * 100) / totalLRUs
                : isFlightOpen ? (currentSecondaryWAPCount * 100) / totalLRUs : (totalSecondaryWAPCount * 100) / totalLRUs
            }
            secondaryFillPercent={(totalSecondaryWAPCount * 100) / totalLRUs}
            primaryLabel={!isFlightOpen
              ? currentStatType === 'count'
                ? totalSecondaryWAPCount
                : (Math.round(totalSecondaryWAPCount * 100 / totalLRUs)).toString().concat('%')
              : currentStatType === 'count'
                ? currentSecondaryWAPCount
                : (Math.round(currentSecondaryWAPCount * 100 / totalLRUs)).toString().concat('%')}
            secondaryLabel={!isFlightOpen
              ? null
              : currentStatType === 'count'
                ? totalSecondaryWAPCount
                : (Math.round(totalSecondaryWAPCount * 100 / totalLRUs)).toString().concat('%')}
            primaryLabelClassName={isFlightOpen && currentMode === 'wap' && isLatest ? styles.highlightBarGraphLabel : null}
            secondaryLabelClassName={currentMode === 'wap' && !isLatest ? styles.highlightBarGraphLabel : null}
            containerClassName={styles.barContainer}
          />
        </Col>
      </Row>
      <Row noGutters
        className={classnames(styles.detailContainer, currentMode === 'disconnected' ? styles.detailContainerActive : '')}
        onClick={() => changeMode('disconnected')}
      >
        <Col>
          <BarGraph
            title="Disconnected"
            primaryFillColor="var(--CL_PINK_1)"
            secondaryFillColor={!isFlightOpen || currentMode === 'disconnected' ? null : "var(--CL_PINK_1_60)"}
            primaryFillPercent={
              currentMode === 'disconnected'
                ? isLatest ? (currentDisconnectedCount * 100) / totalLRUs : (totalDisconnectedCount * 100) / totalLRUs
                : isFlightOpen ? (currentDisconnectedCount * 100) / totalLRUs : (totalDisconnectedCount * 100) / totalLRUs
            }
            secondaryFillPercent={(totalDisconnectedCount * 100) / totalLRUs}
            primaryLabel={!isFlightOpen
              ? currentStatType === 'count'
                ? totalDisconnectedCount
                : (Math.round(totalDisconnectedCount * 100 / totalLRUs)).toString().concat('%')
              : currentStatType === 'count'
                ? currentDisconnectedCount
                : (Math.round(currentDisconnectedCount * 100 / totalLRUs)).toString().concat('%')}
            secondaryLabel={!isFlightOpen
              ? null
              : currentStatType === 'count'
                ? totalDisconnectedCount
                : (Math.round(totalDisconnectedCount * 100 / totalLRUs)).toString().concat('%')}
            primaryLabelClassName={isFlightOpen && currentMode === 'disconnected' && isLatest ? styles.highlightBarGraphLabel : null}
            secondaryLabelClassName={currentMode === 'disconnected' && !isLatest ? styles.highlightBarGraphLabel : null}
            containerClassName={styles.barContainer}
          />
        </Col>
      </Row>
    </Col>
  );
}

FlightStatistics.propTypes = {
  changeLatest: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  changeStatType: PropTypes.func.isRequired,
  currentMode: PropTypes.string.isRequired,
  currentStatType: PropTypes.string.isRequired,
  totalLRUs: PropTypes.number,
  totalITUs: PropTypes.number,
  isFlightOpen: PropTypes.bool,
  isLatest: PropTypes.bool,
  data: PropTypes.object.isRequired,
}

export default FlightStatistics;
