import { ROLES, PERMISSIONS } from 'constants';

export const evaluatePermissions = (
  user,
  roles,
  permissions = undefined,
) => {
  if (roles) {
    return roles.some((rid) => {
      const role = ROLES.getEnumerationItem(rid);
      return role && user.roles.indexOf(role.id) > -1;
    });
  }

  if (permissions) {
    return permissions.some((pid) => {
      const permission = PERMISSIONS.getEnumerationItem(pid);
      if (!permission) {
        return false;
      }
      return user?.roles.some((rid) => {
        const role = ROLES.getEnumerationItem(rid);
        return role?.value.permissions.some((rpid) => {
          const rolePermission = PERMISSIONS.getEnumerationItem(rpid);
          return rolePermission.id === permission.id;
        });
      });
    });
  }

  return true;
}
