import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import { SYSTEM, LruTypeIgnoreList } from 'constants';

import {
  LRU_TYPES,
} from 'constants';
import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';
import classnames from 'classnames';
import ButtonItem from 'components/inputs/ButtonItem';


const LRUTypeFilterOverlay = (props) => {
  const { position, isOpen, onCancel, onDone, filters, } = props;
  const [currentFilters, setCurrentFilters] = useState({});


  const LRU_OPTION_AC = { id: '0', value: SYSTEM };
  const sortedLRUTypes = [LRU_OPTION_AC]
    .concat(
      LRU_TYPES.getSortedEnumerationItems()
        .filter((type) => !LruTypeIgnoreList.includes(type.value))
        .map((item) => {
          const { id, value } = item;
          return { value, id };
        }).sort((a, b) => a.value.localeCompare(b.value)));

  useEffect(() => {
    if (isOpen) {
      setCurrentFilters(filters);
    }
  }, [filters, isOpen]);

  // Return key of city selected
  const onChange = (key) => {
    const newFilters = { ...currentFilters };
    newFilters[key] = !newFilters[key];
    setCurrentFilters(newFilters);
  }

  const selectAll = () => {
    const newFilters = {};
    sortedLRUTypes.forEach((item) => {
      newFilters[item.id] = true;
    });

    setCurrentFilters(newFilters);
  }

  const selectNone = () => {
    setCurrentFilters({});
  }

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button className="button_reg" data-cy-id="EventFilterOverlay-CancelButton" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id="EventFilterOverlay-DoneButton"
            onClick={() => onDone(currentFilters)}
            disabled={Object.keys(currentFilters).filter((key) => currentFilters[key]).length < 1}
          >
            Done
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <Overlay
      data-cy-id="Events-LRUTypeFilterOverlay"
      isOpen={isOpen}
      position={position}
      contentClassName={styles.root}
      footer={<Footer />}
      onCancel={onCancel}
      showClose={false}
    >
      <Row className={styles.header} noGutters>
        <span> Select: </span>
        <Button className={styles.link} onClick={selectAll}>All</Button>
        <span> | </span>
        <Button className={styles.link} onClick={selectNone}>None</Button>
      </Row>
      <Row className={styles.listboxContainer}>
        <Col data-cy-id="Events-LRUTypeFilterOverlay" className={styles.listbox}>
          {
            sortedLRUTypes
              .map((item, index) => {
                const { id, value } = item;
                return (
                  <Col key={index} className={classnames("d-flex align-items-center w-100")} md="auto">
                    <ButtonItem
                      label={value}
                      value={id}
                      type="checkbox"
                      classname={'m-1'}
                      isChecked={currentFilters[id] || false}
                      onChange={() => onChange(id)}
                      idPrefix={id}
                    />
                  </Col>
                );
              })
          }
        </Col>
      </Row>
      <br />
    </Overlay>
  );
}

LRUTypeFilterOverlay.propTypes = {
  position: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default LRUTypeFilterOverlay;
