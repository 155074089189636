import {
  INITIALIZE_APP,
  periodicConfigurationFetch,
  stopPeriodicConfigurationFetch,
  updateConfiguration,
  UNINITIALIZE_APP,
  updateUser,
} from 'actions';
import store from 'store';
import { LRUFactory } from 'classes';
import { LRU_TYPES } from 'constants';

export const FETCH_KIOSK_LRUS = 'FETCH_KIOSK_LRUS';
export const FETCH_KIOSK_LRU_DETAIL = 'FETCH_KIOSK_LRU_DETAIL';

const fiveMinInterval = (5 * 60 * 1000);

export const initializeKioskApp = (user) => {
  const { initialized } = store.getState();
  if (!initialized) {
    return async (dispatch) => {
      await dispatch(updateConfiguration());
      await dispatch(updateUser(user));
      dispatch({
        type: INITIALIZE_APP,
      });

      setTimeout(() => dispatch(periodicConfigurationFetch()), fiveMinInterval);
    }
  } else {
    return async () => { };
  }
}

export const uninitializeKioskApp = () => {
  return async (dispatch) => {
    dispatch({
      type: UNINITIALIZE_APP,
    });

    dispatch(stopPeriodicConfigurationFetch());
  }
}

export const getTop5LRUTypeCollection = () => {
  const defaultOpts = {
    'page': 1,
    'pageSize': 5,
    'sortField': 'st.fa',
    'sortDirection': 'descending',
  }
  const lruData = {};
  return async (dispatch) => {

    const promises = [];
    LRU_TYPES.getSortedEnumerationItems().forEach(async (type) => {
      promises.push(LRUFactory.getLRUsByType(type.value, defaultOpts))
    });

    await Promise.all(promises).then((lrus) => {
      lrus.forEach((lru) => {
        lruData[lru[0].lru_type.value] = lru
      })
    });

    dispatch({
      type: FETCH_KIOSK_LRUS,
      top5LRUTypeCollection: lruData,
    });
  }
}

export const getTop5LRUDetail = (lruType) => {
  const { top5LRUTypeCollection } = store.getState()
  const ids = top5LRUTypeCollection[lruType].map((lru) => lru.id);

  return async (dispatch) => {

    const promises = [];
    ids.forEach(async (id) => {
      promises.push(LRUFactory.getLruById(id));
    });

    const lruData = await Promise.all(promises);

    dispatch({
      type: FETCH_KIOSK_LRU_DETAIL,
      top5LRUDetail: lruData,
    });
  }
}
