import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Aircraft
} from 'models';

import styles from './styles.module.css';

const AircraftMarker = (props) => {
  const {
    aircraft,
    onClick: onClickHandler,
    display,
  } = props;
  const {
    is_connected,
    status,
    tail,
  } = aircraft;

  let statusClass;
  switch (status ? status.id : '4') {
    case '2':
      statusClass = styles.degraded;
      break;
    case '3':
      statusClass = styles.unhealthy;
      break;
    case '4':
      statusClass = styles.offline;
      break;
    default:
      statusClass = styles.operational;
      break;
  }

  return (
    <div
      className={classnames(styles.root, display === 'selected' && styles.selected, display === 'dimmed' && styles.dimmed)}
      onClick={onClickHandler}
    >
      <div className={styles.container}>
        <div className={classnames(styles.icon, statusClass)} />
        {
          is_connected ? null : <div className={styles.disconnectedLine} />
        }
      </div>
      <div className={styles.label}>{tail}</div>
    </div>
  );
}

AircraftMarker.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  display: PropTypes.oneOf(['selected', 'dimmed', 'default']),
  onClick: PropTypes.func,
};

export default AircraftMarker;
