import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
  },
  {
    name: 'SW part',
    key: 'sw_part',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'Config module',
    key: 'config_module',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'Ports',
    key: 'ports',
  },
];

const PowerSupplies = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { power_supplies: powerSupplies } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);

  const localOnRowClick = (lruId, buttonId = null) => {
    setActiveButtonId(buttonId);
    onRowClick(lruId);
  }

  const tableDataMap = {};
  const lopaData = {
    passenger_displays: {}
  };

  powerSupplies.map(({ channels, id, lru_type: lruType }) => {
    const selected = selectedLru === id;
    const typeName = lruType.value;

    if (!tableDataMap[typeName]) {
      tableDataMap[typeName] = [];
    }

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, id);
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }

    lopaData[deviceType][id] = {
      'active': true,
      'selected': selected,
    };

    if (selected && activeButtonId) {
      const channel = channels.find(({ id }) => id === activeButtonId);
      Object.keys(channel).forEach((key) => {
        if (key === 'id') {
          return;
        }
        channel[key].forEach((pd) => {
          lopaData.passenger_displays[pd] = { 'associated': true };
        })
      });
    }

    // Table data
    const lru = aircraft.getLRUByTypePosition(lruType.value, id);
    let config_module;
    let sw_part;
    if (lru?.software_version) {
      const { software_version: { SWCFG, SWPN } } = lru;
      config_module = SWCFG;
      sw_part = SWPN;
    }

    const buttons = channels.map(({ id }, i) => {
      return (
        <div
          key={i}
          className={classnames(
            "skypulsetable_toggle_button",
            (selected && id === activeButtonId)
              ? "active"
              : null
          )}
          data-clickable-id={id}
        >
          {id}
        </div>
      )
    });

    tableDataMap[typeName].push(
      {
        config_module,
        ports: buttons,
        id: id,
        lru: lruType.value,
        pos: id,
        sw_part,
      }
    );
    return true;
  });

  const renderTitle = (title) => {
    return <Row className={styles.lopaDetailsRow}>
      <Col>{title}</Col>
      <Col className={styles.lopaDetailsCol}>
        {(!selectedLru && !activeButtonId) ? null : (<div>LOPA: </div>)}
        {(!selectedLru) ? null : (<div className={styles.lopaItemContainer}>
          <div className={classnames(styles.lru, styles.lruItem)}></div>
          <div>MCU {selectedLru}</div>
        </div>)}
        {(!activeButtonId) ? null : (
          <div className={styles.lopaItemContainer}>
            <div className={classnames(styles.lru, styles.portItem)}></div>
            <div>MCU {selectedLru} - ITUs port {activeButtonId}</div>
          </div>)
        }
      </Col>
    </Row>
  }

  const tableMaxHeight = `calc(100%/${Object.keys(tableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            {
              Object.keys(tableDataMap).map((key, index) => {
                const tData = tableDataMap[key];
                return (
                  <Row
                    key={index}
                    className={styles.multiTable}
                    style={{ maxHeight: tableMaxHeight }}
                    noGutters
                  >
                    <Col className="h-100">
                      <SkyPulseTable
                        title={renderTitle(key)}
                        columns={columns}
                        rows={tData}
                        onRowClick={localOnRowClick}
                        selectedId={selectedLru}
                      />
                    </Col>
                  </Row>
                );
              })
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PowerSupplies.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
}

export default PowerSupplies;
