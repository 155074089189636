import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  AIRPORTS,
  CONNECTION_STATUS_OPTIONS,
  FLIGHT_STATUS_OPTIONS,
} from 'constants';
import { isAlpha } from 'utils';

import {
  Col,
  Row,
  Button,
  Image,
} from 'react-bootstrap';
import CityPickerOverlay from 'components/overlays/CityPickerOverlay';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/TextInput';

import styles from './styles.module.css';
import City from 'assets/icons/City.png';

const idPrefix = 'AircraftSearchPanel';

const AircraftFilterPanel = (props) => {
  const { currentFilters, onClearFilters, onFiltersApply, filtersTemplate } = props;

  const [cityPickerTextField, setCityPickerTextField] = useState(null);
  const { coordinate, fieldName } = cityPickerTextField || {};
  const [filters, setFilters] = useState(Object.assign({}, currentFilters));

  // Refs for underlying inputs of these fields
  const departTextField = useRef();
  const arriveTextField = useRef();

  useEffect(() => {
    setFilters(currentFilters);
  }, [currentFilters]);

  const handleUpdate = ({ target: { name, value } }) => {
    const localFilters = Object.assign({}, filters);
    // We use null to represent 'All' or empty airport code
    switch (name) {
      case 'destination_airport':
      case 'departure_airport':
        if (isAlpha(value) && value.length <= 3) {
          localFilters[name] = value.toUpperCase();
        }
        if (!value) {
          localFilters[name] = null;
        }
        break;
      case 'connection_status':
      case 'aircraft_type':
      case 'flight_status':
        localFilters[name] = (value) ? value : null;
        break;
      default:
        console.error(`${name} is not a valid property of the model AircraftSearch`);
        break;
    }
    setFilters(localFilters);
  }

  // const isValidAirportCode = (code) => code === null || AIRPORTS.getSortedEnumerationItems().some((item) => {
  //   const { iata } = item.value;
  //   return iata === code;
  // });

  const onCityPickerOpen = (ref, name) => {
    const { left, top } = ref.current.getBoundingClientRect();
    setCityPickerTextField({
      fieldName: name,
      coordinate: { x: left, y: top },
    });
  }

  const onCityPickerSelect = (key) => {
    handleUpdate({ target: { name: fieldName, value: AIRPORTS[key].iata } });
    setCityPickerTextField(null);
  }

  return (
    <>
      <Row className={styles.root} data-cy-id="Dashboard-FiltersPanel" noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col>
              <Row noGutters>
                <Col className="title_1">Dashboard</Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles.panel} noGutters>
            <Col lg="auto">
              <Row className={styles.selectContainer} noGutters>
                <Col lg="auto">
                  <Select
                    idPrefix={`${idPrefix}Fleet`}
                    label="Fleet: "
                    name="aircraft_type"
                    data-cy-id="Dashboard-FiltersPanel-FleetSelect"
                    onChange={handleUpdate}
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={filters.aircraft_type || ''}
                  />
                </Col>
              </Row>
              <Row className={styles.textFieldContainer} noGutters>
                <Col lg="auto">
                  <Row noGutters>
                    <Col lg="auto">
                      <TextInput
                        ref={departTextField}
                        idPrefix={`${idPrefix}Depart`}
                        classname={styles.textField}
                        data-cy-id="Dashboard-FiltersPanel-DepartInput"
                        containerClassName={styles.labelText}
                        labelClassName={styles.textFieldLabel}
                        label="Depart:"
                        name="departure_airport"
                        noGutters={true}
                        value={filters.departure_airport || ""}
                        onChange={handleUpdate}
                      />
                    </Col>
                    <Col lg="auto" className={styles.iconContainer}>
                      <Image
                        className={styles.icon}
                        data-cy-id="Dashboard-FiltersPanel-DepartIcon"
                        src={City}
                        alt=""
                        onClick={() => onCityPickerOpen(departTextField, 'departure_airport')}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="auto" className={styles.divider} />
                <Col lg="auto">
                  <Row noGutters>
                    <Col lg="auto">
                      <TextInput
                        ref={arriveTextField}
                        idPrefix={`${idPrefix}Arrive`}
                        classname={styles.textField}
                        data-cy-id="Dashboard-FiltersPanel-ArriveInput"
                        containerClassName={styles.labelText}
                        labelClassName={styles.textFieldLabel}
                        label="Arrive:"
                        name="destination_airport"
                        noGutters={true}
                        value={filters.destination_airport || ""}
                        onChange={handleUpdate}
                      />
                    </Col>
                    <Col lg="auto" className={styles.iconContainer}>
                      <Image
                        className={styles.icon}
                        data-cy-id="Dashboard-FiltersPanel-ArriveIcon"
                        src={City}
                        alt=""
                        onClick={() => onCityPickerOpen(arriveTextField, 'destination_airport')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.selectContainer} noGutters>
                <Col lg="auto">
                  <Select
                    idPrefix={`${idPrefix}FlightStatus`}
                    label="Flight status: "
                    name="flight_status"
                    data-cy-id="Dashboard-FiltersPanel-FlightStatusSelect"
                    onChange={handleUpdate}
                    options={FLIGHT_STATUS_OPTIONS}
                    value={filters.flight_status || ''}
                  />
                </Col>
              </Row>
              <Row className={styles.selectContainer} noGutters>
                <Col lg="auto">
                  <Select
                    idPrefix={`${idPrefix}Connection`}
                    label="Connection: "
                    name="connection_status"
                    data-cy-id="Dashboard-FiltersPanel-ConnectionSelect"
                    onChange={handleUpdate}
                    options={CONNECTION_STATUS_OPTIONS}
                    value={filters.connection_status || ''}
                  />
                </Col>
              </Row>
              <Row className={styles.filterActions} noGutters>
                <Col lg="auto">
                  <Button className="button_reg" data-cy-id="Dashboard-FiltersPanel-ClearButton"
                    onClick={() => {
                      setFilters(Object.assign({}, filtersTemplate));
                      onClearFilters()
                    }}
                  >
                    Clear
                  </Button>
                </Col>
                <Col lg="auto" className={styles.divider} />
                <Col lg="auto">
                  <Button className="button_foc" data-cy-id="Dashboard-FiltersPanel-FilterButton" onClick={() => onFiltersApply(filters)}>
                    Filter
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <CityPickerOverlay position={coordinate} isOpen={(cityPickerTextField !== null)} onCancel={() => setCityPickerTextField(null)} onDone={onCityPickerSelect} />
    </>
  );
}

AircraftFilterPanel.propTypes = {
  currentFilters: PropTypes.object.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onFiltersApply: PropTypes.func.isRequired,
  filtersTemplate: PropTypes.object,
}

export default AircraftFilterPanel;
