import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import LOPA from 'components/LOPA';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from '../styles.module.css';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
  },
  {
    name: 'WAP OS',
    key: 'wap_os',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'WAP Config',
    key: 'wap_config',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'SIB FW',
    key: 'sib_fw',
    showOutliers: true,
  },
  {
    name: 'ITUs served',
    key: 'itus_served',
    size: 'small',
  },
  {
    name: 'SW part number',
    key: 'sw_part_number',
  },
];

const WAPs = (props) => {
  const [activeButtonId, setActiveButtonId] = useState(null);
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { waps } = aircraftLopa;

  const lopaData = {
    passenger_displays: {}
  };
  const tableDataMap = {};

  waps.map(({
    id,
    primary_passenger_displays,
    secondary_passenger_displays,
    lru_type: lruType,
  }) => {
    const selected = selectedLru === id;
    const typeName = lruType.value;

    if (!tableDataMap[typeName]) {
      tableDataMap[typeName] = [];
    }

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, id);
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      'active': true,
      'selected': selected,
    };

    if (selected && activeButtonId) {
      const pds = activeButtonId === 'Primary'
        ? primary_passenger_displays : secondary_passenger_displays;
      pds.forEach((pd) => {
        lopaData.passenger_displays[pd] = { 'associated': true };
      });
    }

    const lru = aircraft.getLRUByTypePosition(lruType.value, id);
    let wap_config;
    let wap_os;
    let sib_fw;
    if (lru?.software_version) {
      const { software_version: { CFG, OSV, SFW } } = lru;
      wap_config = CFG;
      wap_os = OSV;
      sib_fw = SFW;
    }

    const buttons = ['Primary', 'Secondary'].map((display, i) => {
      return (
        <div
          key={i}
          className={classnames(
            "skypulsetable_toggle_button",
            (selected && display === activeButtonId)
              ? "active"
              : null
          )}
          data-clickable-id={display}
        >
          {display}
        </div>
      )
    });

    tableDataMap[typeName].push(
      {
        id: id,
        itus_served: buttons,
        lru: lruType.value,
        pos: id,
        sib_fw,
        wap_os,
        wap_config,
      }
    );
    return true;
  });

  const localOnRowClick = (lruId, buttonId = null) => {
    setActiveButtonId(buttonId);
    onRowClick(lruId);
  }

  const renderTitle = (title) => {
    return (<Row className={styles.lopaDetailsRow}>
      <Col> {title} </Col>
      <Col className={styles.lopaDetailsCol}>
        {(!activeButtonId && !selectedLru) ? null : (<div>LOPA: </div>)}

        {(!selectedLru) ?
          null :
          (<div className={styles.lopaItemContainer}>
            <div className={classnames(styles.lru, styles.lruItem)}></div>
            <div>WAP {selectedLru}</div>
          </div>)
        }

        {(!activeButtonId) ?
          null :
          (<div className={styles.lopaItemContainer}>
            <div className={classnames(styles.lru, styles.portItem)}></div>
            <div>WAP {selectedLru} - ITUs {activeButtonId}</div>
          </div>)
        }
      </Col>
    </Row>);
  }

  const tableMaxHeight = `calc(100%/${Object.keys(tableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            {
              Object.keys(tableDataMap).map((key, index) => {
                const tData = tableDataMap[key];
                return (
                  <Row
                    key={index}
                    className={styles.multiTable}
                    style={{ maxHeight: tableMaxHeight }}
                    noGutters
                  >
                    <Col className="h-100">
                      <SkyPulseTable
                        title={renderTitle(key)}
                        columns={columns}
                        rows={tData}
                        onRowClick={localOnRowClick}
                        selectedId={selectedLru}
                      />
                    </Col>
                  </Row>
                );
              })
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

WAPs.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
}

export default WAPs;
