import React from 'react';
import PropTypes from 'prop-types';

import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  }, {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
  }, {
    name: 'ITU ISD',
    key: 'itu_isd',
    size: 'xsmall',
  }, {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
  }, {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
  }, {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    size: 'small',
  }
];

const CrewPanels = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let itu_fss;
  let itu_isd;
  let map_sw_part;
  let map_assets_sw_part;
  let itu_rom;
  if (latestLRUHistory?.software_version) {
    const { FSS, ISD, MAP, MASA, ROM, } = latestLRUHistory.software_version;
    itu_fss = FSS;
    itu_isd = ISD;
    itu_rom = ROM;
    map_assets_sw_part = MASA;
    map_sw_part = MAP;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      itu_rom: itu_rom,
      itu_isd: itu_isd,
      itu_fss: itu_fss,
      map_sw_part: map_sw_part,
      map_assets_sw_part: map_assets_sw_part,
    }
  ];


  return (
    <Row className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={overviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

CrewPanels.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
}

export default CrewPanels;
