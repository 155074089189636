import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  AIRCRAFT_TYPES,
  PATHS,
  SELECT_OPTION_ALL,
  DEFAULT_API_REQUEST_PAGE_SIZE,
  LRU_TYPES
} from 'constants';
import { LRUFactory } from 'classes';
import { Aircraft } from 'models';
import { getUniqueLRUPositions } from 'utils';

import { Col, Row, Button } from 'react-bootstrap';
import LRUDetail from 'view/components/overlays/LRUDetail';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const columnDefinitions = [
  {
    name: 'Tail',
    key: 'tail',
    sortable: true,
    size: 'xsmall',
    sortKey: 'tl',
  },
  {
    name: 'Fleet',
    key: 'fleet',
    size: 'small',
    sortable: true,
    sortKey: 'at',
  },
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    sortable: true,
    sortKey: 'ps',
  },
  {
    name: 'Last flight',
    key: 'last_flight',
    sortable: true,
    size: 'xsmall',
    sortKey: 'st.fc',
  },
  {
    name: '7 day average',
    key: 'seven_day_average',
    sortable: true,
    size: 'xsmall',
    sortKey: 'st.fa',
  }
];

const idPrefix = 'Health';

const filtersTemplate = {
  fleet: '',
  tail: '',
  pos: '',
};

const Health = (props) => {
  const {
    aircraftList,
    lruTypeId,
    onTailClick,
    push,
    reportsEnabled,
  } = props;

  const [lruData, setLRUData] = useState([]);
  const [filters, setFilters] = useState(Object.assign({}, filtersTemplate));
  const [filtersApplied, setFiltersApplied] = useState(Object.assign({}, filtersTemplate));

  // pagination states
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const currentRequest = useRef(null);

  // sorting states
  const [sortParameters, setSortParameters] = useState({
    sortKey: 'st.fc', // default sort by last flight
    sortDirection: 'descending',
  });

  // lru detail overlay
  const [lruDetailOverlayProps, setLRUDetailOverlayProps] = useState({ id: null, isOpen: false });

  useEffect(() => {
    setFilters(Object.assign({}, filtersTemplate));
    setFiltersApplied(Object.assign({}, filtersTemplate));
  }, [lruTypeId])

  // fetch new data when type or sort field changes
  useEffect(() => {
    setLRUData([]);
    setPageNumber(1);
    setHasNextPage(true);
    setIsLoading(true);

    return () => {
      if (currentRequest.current) {
        currentRequest.current.cancel();
      }
    }
  }, [lruTypeId, sortParameters, filtersApplied]);

  // fetch new data when on scroll end
  const handleScrollEnd = () => {
    const fetchLRUData = async (typeId, page, sortField, sortDirection, aircraftType, tail, pos) => {
      setIsLoading(true);

      const promise = LRUFactory.getLRUsByType(typeId, {
        page,
        pageSize: DEFAULT_API_REQUEST_PAGE_SIZE,
        sortField,
        sortDirection,
        aircraftType,
        tail,
        pos,
      });

      currentRequest.current = promise;

      promise.then((newLRUData) => {
        if (newLRUData) {
          setHasNextPage(newLRUData && newLRUData.length === DEFAULT_API_REQUEST_PAGE_SIZE);
          setLRUData(lruData => lruData.concat(newLRUData));
          setPageNumber(page => page + 1);
          setIsLoading(false);
        }
      });
    }

    if (hasNextPage) {
      const { sortDirection, sortKey } = sortParameters;
      const { fleet: aircraftType, tail, pos } = filtersApplied;
      fetchLRUData(lruTypeId, pageNumber, sortKey, sortDirection, aircraftType, tail, pos);
    }
  }

  const onUpdateSortParameters = (newSortParameters) => {
    const { columnKey, sortDirection } = newSortParameters;
    const sortKey = (columnKey)
      ? columnDefinitions.find(({ key }) => key === columnKey).sortKey
      : null;
    setSortParameters({ sortKey, sortDirection });
  }

  const handleFiltersUpdate = ({ target: { name, value } }) => {
    const localFilters = Object.assign({}, filters);
    localFilters[name] = (value) ? value : '';
    setFilters(localFilters);
  }

  const lruName = LRU_TYPES.getSortedEnumerationItems().find((type) => type.id === lruTypeId).value;
  let filteredAircraft = aircraftList;
  filteredAircraft = aircraftList ? aircraftList.filter((a) =>
    (filtersApplied.fleet === '' ? true : a.aircraft_type.id === filtersApplied.fleet)
    && (filtersApplied.tail === '' ? true : a.tail === filtersApplied.tail)
  ) : [];

  const tailOptions = [SELECT_OPTION_ALL]
    .concat((
      (!filters.fleet)
        ? filteredAircraft
        : filteredAircraft
          .filter((aircraft) => aircraft?.aircraft_type?.id === filters.fleet)
    ).map((aircraft) => {
      return {
        value: aircraft.tail,
        name: aircraft.tail,
      };
    }).sort((a, b) => a.name.localeCompare(b.name)));


  const pos = getUniqueLRUPositions(
    filters.fleet === ''
      ? filters.tail !== ''
        ? AIRCRAFT_TYPES.getSortedEnumerationItems()
          .filter((at) => at.id === filteredAircraft.find((a) => a.tail === filters.tail).aircraft_type.id)
        : AIRCRAFT_TYPES.getSortedEnumerationItems()
      : AIRCRAFT_TYPES.getSortedEnumerationItems().filter((at) => at.id === filters.fleet),
    lruTypeId);

  const position = [SELECT_OPTION_ALL]
    .concat(
      pos.map((p) => {
        return {
          value: p,
          name: p
        }
      })
    );

  const data = [];
  lruData.forEach((lru) => {
    const aircraft = filteredAircraft.find((ac) => ac.id === lru.aircraft_id);
    if (aircraft && (filtersApplied.pos === '' || filtersApplied.pos === lru.position)) {
      const tailButton = (<div
        className="skypulsetable_toggle_button"
        onClick={() => onTailClick(aircraft?.tail)}
      >
        {aircraft?.tail}
      </div >);
      data.push(
        {
          id: lru.id,
          tail: { display: tailButton, sort: aircraft?.tail },
          fleet: aircraft?.aircraft_type.value.name,
          lru: lru.lru_type.value,
          pos: lru.position,
          last_flight: lru.stats.fault_count_current_flight,
          seven_day_average: Math.round(lru.stats.fault_count_seven_day_average)
        }
      );
    }
  });

  const handleClickLru = (id) => {
    setLRUDetailOverlayProps({ id, isOpen: true });
  }

  const handleOnClose = () => {
    setLRUDetailOverlayProps({ id: null, isOpen: false });
  }

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col className="h-100">
          <Row className={styles.title} noGutters>
            <Col className="title_1">{lruName} health</Col>
            <Col md="auto">
              {
                (reportsEnabled ?
                  (
                    <Button
                      className="button_reg"
                      data-cy-id="LRU-LRUFaultsReportButton" onClick={() => push(PATHS.reportLruFaults)}
                    >
                      LRUs & Faults reports
                    </Button>
                  )
                  : null
                )
              }
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id="LRU-Health-Filters" noGutters>
            <Col>
              <Row className={styles.filterContainer} noGutters>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-${lruName}-Fleet`}
                    label="Fleet: "
                    name="fleet"
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={filters.fleet}
                    onChange={handleFiltersUpdate}
                  />
                </Col>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-${lruName}-tail`}
                    label="Tail: "
                    name="tail"
                    options={tailOptions}
                    value={filters.tail}
                    onChange={handleFiltersUpdate}
                  />
                </Col>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-${lruName}-pos`}
                    label="Pos: "
                    name="pos"
                    options={position}
                    value={filters.pos}
                    onChange={handleFiltersUpdate}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Row className={styles.filterActions} noGutters>
                <Col>
                  <Button
                    className="button_reg"
                    onClick={() => {
                      setFilters(Object.assign({}, filtersTemplate));
                      setFiltersApplied(Object.assign({}, filtersTemplate));
                    }}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="button_foc"
                    onClick={() => setFiltersApplied(filters)}
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles.tableRow} noGutters>
            <Col className="h-100">
              <SkyPulseTable
                key={lruTypeId}
                columns={columnDefinitions}
                rows={data}
                title={`${lruName} - Faults per flight`}
                onRowClick={handleClickLru}
                defaultSort="last_flight"
                defaultSortState="descending"
                onScrollEnd={handleScrollEnd}
                hasMoreData={hasNextPage}
                isLoading={isLoading}
                onUpdateSortParameters={onUpdateSortParameters}
              />
            </Col>
          </Row>
        </Col>
      </Row >
      <LRUDetail
        {...lruDetailOverlayProps}
        onClose={handleOnClose}
      />
    </>
  );
}

Health.propTypes = {
  aircraftList: PropTypes.arrayOf(PropTypes.instanceOf(Aircraft)),
  lruTypeId: PropTypes.string.isRequired,
  onTailClick: PropTypes.func.isRequired
}

export default Health;
