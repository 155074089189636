import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  },
  {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'ITU ISD',
    key: 'itu_isd',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    showOutliers: true,
  },
  {
    name: 'Class',
    key: 'class',
    size: 'xsmall',
  },
  {
    name: 'Audio zone',
    key: 'audio_zone',
    size: 'xsmall',
  },
  {
    name: 'MCU',
    key: 'mcu',
    size: 'xsmall',
  }
];

const azButtonId = 'az';
const classButtonId = 'cs';
const mcuButtonId = 'mcu';

const PassengerDisplays = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { passenger_displays: passengerDisplays, classes } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);

  const localOnRowClick = (lruId, buttonId = null) => {
    setActiveButtonId(buttonId);
    onRowClick(lruId);
  }

  const selectedLOPAITU = passengerDisplays.find((pd) => pd.id === selectedLru);
  const selectedLOPAITUClass = (selectedLOPAITU) ? classes.find(({ id }) => id === selectedLOPAITU.class) : undefined;
  const selectedLOPAITUAudioZone = selectedLOPAITUClass?.audio_zone;
  const selectedLOPAITUMCUID = selectedLOPAITU?.power_supply;
  const selectedLOPAITUMCUChannel = selectedLOPAITU?.power_supply_channel;

  const lopaData = {};
  const tableDataMap = {};
  passengerDisplays.map((pd) => {
    const {
      class: lopaITUClassId,
      id,
      lru_type: lruType,
      power_supply: poweSupplyId,
      power_supply_channel: poweSupplyChannel,
    } = pd;
    const selected = selectedLru === id;
    const lopaITUClass = classes.find(({ id }) => id === lopaITUClassId);
    const ituAudioZone = lopaITUClass?.audio_zone; const typeName = lruType.value;

    if (!tableDataMap[typeName]) {
      tableDataMap[typeName] = [];
    }

    let associated = false;
    if (activeButtonId) {
      switch (activeButtonId) {
        case azButtonId:
          associated = (ituAudioZone === selectedLOPAITUAudioZone);
          break;
        case classButtonId:
          associated = (lopaITUClass.id === selectedLOPAITUClass.id);
          break;
        case mcuButtonId:
          associated = (poweSupplyId === selectedLOPAITUMCUID && poweSupplyChannel === selectedLOPAITUMCUChannel);
          break;
        default:
          break;
      }
    }
    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, id);
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      'active': true,
      'selected': selected,
      'associated': associated
    };

    const lru = aircraft.getLRUByTypePosition(lruType.value, id);
    let itu_isd;
    let itu_fss;
    let itu_rom;
    let map_assets_sw_part;
    let map_sw_part;

    if (lru?.software_version) {
      const { FSS, ISD, MAP, MASA, ROM, } = lru.software_version;
      itu_fss = FSS;
      itu_isd = ISD;
      itu_rom = ROM;
      map_assets_sw_part = MASA;
      map_sw_part = MAP;
    }

    const azButton = (<div
      className={classnames(
        "skypulsetable_toggle_button",
        (selected && activeButtonId === azButtonId)
          ? "active"
          : null
      )}
      data-clickable-id={azButtonId}
    >
      {ituAudioZone}
    </div>);

    const classButton = (<div
      className={classnames(
        "skypulsetable_toggle_button",
        (selected && activeButtonId === classButtonId)
          ? "active"
          : null
      )}
      data-clickable-id={classButtonId}
    >
      {lopaITUClass.name}
    </div>);

    const mcuButton = (<div
      className={classnames(
        "skypulsetable_toggle_button",
        (selected && activeButtonId === mcuButtonId)
          ? "active"
          : null
      )}
      data-clickable-id={mcuButtonId}
    >
      {`MCU ${poweSupplyId}-J${poweSupplyChannel}`}
    </div>);

    tableDataMap[typeName].push({
      audio_zone: azButton,
      class: classButton,
      itu_fss,
      itu_isd,
      itu_rom,
      lru: lruType.value,
      map_assets_sw_part,
      map_sw_part,
      mcu: mcuButton,
      pos: id,
      id: id
    });
    return true;
  });

  const activeButtonIdToData = {
    'az': 'audio zone ' + selectedLOPAITUClass?.audio_zone,
    'cs': 'class ' + selectedLOPAITUClass?.id,
    'mcu': 'MCU ' + selectedLOPAITUMCUID + ' port J' + selectedLOPAITUMCUChannel
  }

  const renderTitle = (title, index) => {
    return <Row className={styles.lopaDetailsRow}>
      <Col>{title}</Col>
      {index > 0 ? null
        : (
          <Col className={styles.lopaDetailsCol}>
            {(!selectedLOPAITU && !activeButtonId) ? null : (<div>LOPA: </div>)}
            {(!selectedLOPAITU) ? null : (<div className={styles.lopaItemContainer}>
              <div className={classnames(styles.lru, styles.lruItem)}></div>
              <div>ITU {selectedLOPAITU.id}</div>
            </div>)}
            {(!activeButtonId) ? null : (
              <div className={styles.lopaItemContainer}>
                <div className={classnames(styles.lru, styles.portItem)}></div>
                <div>ITUs - {activeButtonIdToData[activeButtonId]} </div>
              </div>)
            }
          </Col>
        )
      }
    </Row>
  }

  const tableMaxHeight = `calc(100%/${Object.keys(tableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100">
            {
              Object.keys(tableDataMap).map((key, index) => {
                const tData = tableDataMap[key];
                return (
                  <Row
                    key={index}
                    className={styles.multiTable}
                    style={{ maxHeight: tableMaxHeight }}
                    noGutters
                  >
                    <Col className="h-100">
                      <SkyPulseTable
                        title={renderTitle(key, index)}
                        columns={columns}
                        rows={tData}
                        onRowClick={localOnRowClick}
                        selectedId={selectedLru}
                      />
                    </Col>
                  </Row>
                );
              })
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PassengerDisplays.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
}

export default PassengerDisplays;
