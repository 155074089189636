import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import prmIcon from 'assets/icons/AIX/prmDark.png';
import coffeeIcon from 'assets/icons/AIX/coffee.png';
import LavatoryIcon from 'assets/icons/AIX/lavatory.png';
import windowIcon from 'assets/icons/AIX/windowShade.png';
import trolleyIcon from 'assets/icons/AIX/trolley.png';
import styles from './styles.module.css';
const prmColumns = [
  {
    name: 'Id',
    key: 'id',
    size: 'medium',
  },
  {
    name: 'Lock',
    key: 'lock',
    size: 'medium',
  },
];
const standardColumns = [
  {
    name: 'Id',
    key: 'id',
    size: 'medium',
  },
  {
    name: 'Item',
    key: 'item',
    size: 'medium',
  },
  {
    name: 'Status',
    key: 'status',
    size: 'medium',
  },
];
const cartColumns = [
  {
    name: 'Id',
    key: 'id',
    size: 'medium',
  },
  {
    name: 'Location',
    key: 'location',
    size: 'medium',
  },
  {
    name: 'Weight',
    key: 'weight',
    size: 'medium',
  },
];
const windowColumns = [
  {
    name: 'Location',
    key: 'location',
    size: 'medium',
  },
  {
    name: 'status',
    key: 'status',
    size: 'medium',
  },
];
const data = {
  prm: [
    {
      id: 'Seat 1B',
      lock: 'Disengaged',
    },
    {
      id: 'Seat 18C',
      lock: 'Engaged',
    },
  ],
  coffee: [
    {
      id: 'Coffee macker G1',
      item: 'Brew status',
      status: 'Brewing',
    },
    {
      id: 'Coffee macker G1',
      item: 'Handle',
      status: 'Locked',
    },
    {
      id: 'Coffee macker G1',
      item: 'Tank 1',
      status: '195 F',
    },
    {
      id: 'Coffee macker G1',
      item: 'Tank 2',
      status: '195 F',
    },
    {
      id: 'Coffee macker G1',
      item: 'Carafe',
      status: '180 F',
    },
    {
      id: 'Coffee macker G1',
      item: 'Uptime',
      status: '2:32',
    },
  ],
  lavatories: [
    {
      id: 'Lavatory 1',
      item: 'Waste flap',
      status: 'Closed',
    },
    {
      id: 'Lavatory 1',
      item: 'Toilet lid',
      status: 'Open',
    },
    {
      id: 'Lavatory 1',
      item: 'Flush',
      status: '8 minutes ago',
    },
  ],
  carts: [
    {
      id: 'Bev cart 1',
      location: 'Gallery One',
      weight: ' ',
    },
    {
      id: 'Waste cart 1',
      location: 'Roaming',
      weight: '4.5 lb',
    },
  ],
  windowShades: [
    {
      location: '1A',
      status: 'Open',
    },
    {
      location: '1F',
      status: 'Open',
    },
    {
      location: '2A',
      status: '50%',
    },
  ],
};
const General = () => {
  return (
    <div>
      <Row noGutters>
        <Col className={styles.leftPanel}>
          <Row className={styles.equipmentContainer_prm}>
            <Col className={styles.iconContainer}>
              <span className={styles.title}> PRM Seats </span>
              <span className={styles.prmIconContainer}>
                <img src={prmIcon} alt="" className={styles.prmIcon} />
              </span>
            </Col>
            <Col className={styles.tableContainer}>
              <SkyPulseTable
                columns={prmColumns}
                rows={data.prm}
                tableClassname={styles.table}
              />
            </Col>
          </Row>
          <Row className={styles.equipmentContainer_beverage}>
            <Col className={styles.iconContainer}>
              <span className={styles.title}> Coffee Makers </span>
              <img src={coffeeIcon} alt="" className={styles.icon} />
            </Col>
            <Col className={styles.tableContainer}>
              <SkyPulseTable
                columns={standardColumns}
                rows={data.coffee}
                tableClassname={styles.table}
              />
            </Col>
          </Row>
          <Row className={styles.equipmentContainer_oven}>
            <Col className={styles.iconContainer}>
              <span className={styles.title}> Lavatories </span>
              <img src={LavatoryIcon} alt="" className={styles.lavIcon} />
            </Col>
            <Col className={styles.tableContainer}>
              <SkyPulseTable
                columns={standardColumns}
                rows={data.lavatories}
                tableClassname={styles.table}
              />
            </Col>
          </Row>
        </Col>
        <Col className={styles.rightPanel}>
          <Row className={styles.equipmentContainer_trolley}>
            <Col className={styles.iconContainer}>
              <span className={styles.title}> Carts </span>
              <img src={trolleyIcon} alt="" className={styles.cartIcon} />
            </Col>
            <Col className={styles.tableContainer}>
              <SkyPulseTable
                columns={cartColumns}
                rows={data.carts}
                tableClassname={styles.table}
              />
            </Col>
          </Row>
          <Row className={styles.equipmentContainer_shades}>
            <Col className={styles.iconContainer}>
              <span className={styles.title}> Window Shades </span>
              <img src={windowIcon} alt="" className={styles.windowIcon} />
            </Col>
            <Col className={styles.tableContainer}>
              <SkyPulseTable
                columns={windowColumns}
                rows={data.windowShades}
                tableClassname={styles.table}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default General;
