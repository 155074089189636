import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import config from 'config';
import { Aircraft, Flight } from 'models';

import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import AircraftMarker from './AircraftMarker';
import AircraftRoute from './AircraftRoute';
import ButtonItem from 'components/inputs/ButtonItem';

import styles from './styles.module.css';
import { AIRCRAFT_STATUS_OFFLINE, DEFAULT_MAP_CENTERPOINT } from 'constants';

const MapPanel = (props) => {
  const {
    activeAircraft,
    activeFlight,
    aircraftList,
    enableControls,
    onAircraftSelect,
    onFiltersChange,
    showDisconnected,
    zoom,
  } = props;

  const idPrefix = `${props.idPrefix}-MapPanel`
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [mapConfig, setMapConfig] = useState({
    key: config.google.maps.key,
    center: DEFAULT_MAP_CENTERPOINT,
    zoom: zoom,
    style: config.google.maps.style,
  })

  const displayAircraft = useMemo(() => {
    let isActiveAircraftInFilter = false;
    const aircrafts = aircraftList.filter((aircraft) => {
      // filter out offline
      if (aircraft.status.id === AIRCRAFT_STATUS_OFFLINE.id) {
        return false;
      }

      // filter out disconnected
      if (!aircraft.is_connected && !showDisconnected) {
        return false;
      }

      const flight = aircraft.getCurrentFlight();
      if (!flight) {
        return false;
      }

      const { latitude, longitude } = flight;
      if (!latitude || !longitude) {
        return false;
      }

      // Removing selected aircraft from the list and adding it last
      if (activeAircraft && aircraft.id === activeAircraft.id) {
        // Only add active aircraft back if it was not filtered out previously
        isActiveAircraftInFilter = true;
        return false;
      }

      return true;
    })

    // Adding selected aircraft which was removed before
    if (activeAircraft && isActiveAircraftInFilter && activeAircraft.status.id !== AIRCRAFT_STATUS_OFFLINE.id) {
      aircrafts.push(activeAircraft);
    }

    return aircrafts;
  }, [aircraftList, activeAircraft, showDisconnected]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const flight = activeAircraft && displayAircraft.includes(activeAircraft)
      ? activeAircraft.getCurrentFlight()
      : null;

    if (flight) {
      // Set config based on the selected aircraft
      if (
        mapConfig.center.lat !== flight?.latitude ||
        mapConfig.center.lng !== flight?.longitude
      ) {
        setMapConfig(s => ({
          ...s,
          center: {

            lat: flight.latitude,
            lng: flight.longitude,
          }
        }));
      }
    }
    else {
      if (isInitialLoad) {
        const bounds = (window.google)
          ? new window.google.maps.LatLngBounds()
          : null;

        // find the edges
        displayAircraft.forEach((aircraft) => {
          const flight = aircraft.getCurrentFlight();
          if (flight?.latitude && flight?.longitude) {
            const { latitude, longitude } = flight;
            if (bounds) {
              bounds.extend(
                new window.google.maps.LatLng(latitude || 0, longitude || 0)
              );
            }
          }
        });

        if (displayAircraft.length > 0) {
          map.fitBounds(bounds);
          setIsInitialLoad(false);
        }
      }
    }
  }, [displayAircraft, map, mapConfig.center, activeAircraft, isInitialLoad]);

  const recenter = useCallback(() => {
    if (!map) {
      return;
    }

    if (displayAircraft.length < 1) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    displayAircraft.forEach((aircraft) => {
      const flight = aircraft.getCurrentFlight();
      if (flight?.latitude && flight?.longitude) {
        const { latitude, longitude } = flight;
        return bounds.extend(
          new window.google.maps.LatLng(latitude || 0, longitude || 0)
        );
      }
    });
    map.fitBounds(bounds);
  }, [displayAircraft, map]);

  return (
    <div
      ref={mapRef}
      data-cy-id={idPrefix}
      className={styles.root}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setMap(map);
        }}
        bootstrapURLKeys={{ key: mapConfig.key }}
        center={mapConfig.center}
        zoom={mapConfig.zoom}
        options={{
          disableDefaultUI: true,
          styles: mapConfig.style,
          scrollwheel: enableControls,
        }}
      >
        {displayAircraft
          .map((aircraft, index) => {
            const isSelected = activeAircraft ? (activeAircraft && aircraft.id === activeAircraft.id) : null;
            const display = activeAircraft
              ? (aircraft.id === activeAircraft.id ? 'selected' : 'dimmed')
              : 'default';
            const flight = aircraft.getCurrentFlight();
            return (
              <AircraftMarker
                key={index}
                aircraft={aircraft}
                isSelected={isSelected}
                display={display}
                lat={(flight) ? flight.latitude : 0}
                lng={(flight) ? flight.longitude : 0}
                onClick={() => onAircraftSelect(aircraft.id)}
              />
            )
          })
        }
        {map && (
          <AircraftRoute
            map={map}
            activeAircraft={activeAircraft}
            activeFlight={activeFlight}
          />
        )}
      </GoogleMapReact>
      {
        !enableControls
          ? null
          : (
            <Row className={styles.mapControlPanel} noGutters>
              <Col className={styles.checkboxContainer}>
                <ButtonItem
                  label="Show disconnected"
                  value="showDisconnected"
                  onChange={(e) => onFiltersChange(e.target.checked)}
                  type="checkbox"
                  className={styles.checkbox}
                  isChecked={showDisconnected}
                  data-cy-id={`${idPrefix}-DisconnectedCheckbox`}
                />
              </Col>
              <Col md="auto">
                <Button
                  variant="light"
                  className={classnames(styles.button, styles.zoomAllButton)}
                  data-cy-id={`${idPrefix}-ZoomAllButton`}
                  onClick={recenter}
                >
                  Zoom to all
          </Button>
              </Col>
              <Col md="auto">
                <Button
                  variant="light"
                  className={styles.button}
                  onClick={() => {
                    setMapConfig(s => ({ ...s, zoom: map.getZoom() === 1 ? 1 : map.getZoom() - 1 }))
                  }}
                  data-cy-id={`${idPrefix}-ZoomOutButton`}
                >
                  -
          </Button>
              </Col>
              <Col md="auto" className={styles.buttonContainer}>
                <Button
                  variant="light"
                  className={styles.button}
                  onClick={() => setMapConfig(s => ({ ...s, zoom: map.getZoom() === 20 ? 20 : map.getZoom() + 1 }))}
                  data-cy-id={`${idPrefix}-ZoomInButton`}
                >
                  +
          </Button>
              </Col>
            </Row>
          )
      }
    </div>
  );
}

MapPanel.defaultProps = {
  zoom: 3,
  enableControls: true,
};

MapPanel.propTypes = {
  activeAircraft: PropTypes.instanceOf(Aircraft),
  activeFlight: PropTypes.instanceOf(Flight),
  aircraftList: PropTypes.arrayOf(PropTypes.instanceOf(Aircraft)),
  enableControls: PropTypes.bool,
  idPrefix: PropTypes.string,
  onAircraftSelect: PropTypes.func,
  onFiltersChange: PropTypes.func,
  showDisconnected: PropTypes.bool,
  zoom: PropTypes.number,
}

export default MapPanel;
