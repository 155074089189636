import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL
} from 'constants';
import { getUTCShortDate } from 'utils';
import { ReportFactory } from 'classes';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';

import styles from './styles.module.css';

const idPrefix = 'UsageReport';
const Usage = (props) => {

  const {
    aircraftList,
    flightId,
    flightList
  } = props;

  const flight = !flightId ? null : flightList.find((f) => f.id === flightId);
  const aircraftId = !flight ? null : flight.aircraft_id;
  const aircraft = !aircraftId ? null : aircraftList?.find((ac) => ac.id === aircraftId);


  let flightArrTimestamp = 0;
  if (flight) {
    if (flight.arrival_timestamp) {
      flightArrTimestamp = new Date(flight.arrival_timestamp);
    } else {
      const flightLatestHistory = flight?.getLatestHistory() || {};
      const flightLatestHistoryTimestamp = flightLatestHistory.created || 0;
      const ttd = flightLatestHistory.time_to_destination || 0;
      flightArrTimestamp = new Date(flightLatestHistoryTimestamp + (ttd * 60 * 1000));
    }
  }

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [shipOption, setShipOption] = useState(aircraft ? aircraft.tail : '');
  const [arrDt, setArrDt] = useState(flightArrTimestamp);
  const [overlayOpen, setOverlayOpen] = useState(flight && aircraft ? true : false);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [selectedAircraftId, setSelectedAircraftId] = useState(aircraftId ? aircraftId : '');

  const ships = [SELECT_OPTION_ALL]
    .concat(!aircraftList
      ? []
      : ((!fleetOption)
        ? aircraftList
        : aircraftList
          .filter((aircraft) => aircraft?.aircraft_type?.id === fleetOption)
      ).map((aircraft) => {
        return {
          value: aircraft.id,
          name: aircraft.tail,
        };
      }).sort((a, b) => a.name.localeCompare(b.name)));

  let arrivalDate = 0;
  if (arrDt > 0) {
    arrivalDate = new Date(arrDt);
    arrivalDate = new Date(arrivalDate.setHours(0, 0, 0, 0)).getTime();
  }

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col className="title_1"> Usage report </Col>
            <Col md="auto" className={styles.note}>
              Note: Data is available for previous 30 days.
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id={`${idPrefix}-Filters`} noGutters>
            <Col>
              <Row className={styles.filterContainer}>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setFleetOption(value);
                      setShipOption('');
                      setSelectedAircraftId('')
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Ship`}
                    label="Tail: "
                    options={ships}
                    value={selectedAircraftId}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      const selectedAircraft = aircraftList.find((ac) => ac.id === value);
                      setShipOption(selectedAircraft ? selectedAircraft.tail : '');
                      setSelectedAircraftId(value);
                    }}
                  />
                </Col>
                <Col md="auto">
                  <DatePicker
                    idPrefix={idPrefix}
                    value={arrivalDate}
                    label="Arrival date:"
                    onChange={(date) => setArrDt(parseInt(date, 10))}
                    minPreviousDays={30}
                    maxDate={Date.now()}
                  />
                </Col>
              </Row>
            </Col>

            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-CreateReportButton`}
                disabled={arrivalDate === 0}
                onClick={() => setOverlayOpen(true)}
              >
                Create report
            </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="Usage report"
        data={<>
          <Row>
            <Col> Fleet </Col>
            <Col> {fleetOption === '' ? 'All' : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name} </Col>
          </Row>
          <Row>
            <Col> Tail </Col>
            <Col> {shipOption === '' ? 'All' : shipOption} </Col>
          </Row>
          <Row>
            <Col> Arrival date </Col>
            <Col> {getUTCShortDate(arrivalDate)} </Col>
          </Row>
        </>}
        isOpen={overlayOpen}
        onCancel={() => setOverlayOpen(false)}
        loading={messageOverlayOpen}
        onDone={async () => {
          setMessageOverlayOpen(true);
          if (shipOption !== '') {
            await ReportFactory.getUsageReportByAircraftId(selectedAircraftId, arrivalDate);
          } else if (fleetOption !== '') {
            await ReportFactory.getUsageReportByAircraftType(fleetOption, arrivalDate);
          } else {
            await ReportFactory.getUsageReport(arrivalDate);
          }
          setMessageOverlayOpen(false);
          setOverlayOpen(false);
        }}
      />

    </>
  );
}

export default Usage;
