import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
} from 'constants';
import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import PropTypes from 'prop-types';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const columns = [
  {
    name: 'Tail',
    key: 'tail',
    sortable: true,
    size: 'medium'
  },
  {
    name: 'Fleet',
    key: 'type',
    sortable: true,
    size: 'medium',
  }
];

const idPrefix = 'ShipSelect';

const ShipSelect = (props) => {
  const {
    rAircraftList,
    onCancel,
    onSelect,
  } = props;

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);

  const onSelectChange = ({ target: { value } }) => {
    setFleetOption(value);
  }


  const filteredAircraftList = (
    !rAircraftList
      ? []
      : (!fleetOption)
        ? rAircraftList
        : rAircraftList
          .filter((aircraft) => aircraft.aircraft_type.id === fleetOption)
  ).map((aircraft) => {
    return {
      id: aircraft.id,
      tail: aircraft.tail,
      type: aircraft.aircraft_type.value.name,
    };
  });

  return (
    <Row className={styles.root} data-cy-id="ShipSelect" noGutters>
      <Col className={styles.body}>
        <Row className={styles.bodyHeaderRow} noGutters>
          <Col className="title_1">Ship selection</Col>
          <Col md="auto">
            <Button className="button_reg" data-cy-id="ShipSelect-CancelButton" onClick={onCancel}> Cancel </Button>
          </Col>
        </Row>
        <Row className={styles.fleetSelectionRow} data-cy-id="ShipSelect-FiltersPanel" noGutters>
          <Col md="auto">
            <Select
              idPrefix={idPrefix}
              data-cy-id="ShipSelect-FiltersPanel-FleetSelect"
              label="Fleet: "
              onChange={onSelectChange}
              options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
              value={fleetOption}
            />
          </Col>
        </Row>
        <Row className={styles.shipListRow} data-cy-id="ShipSelect-ListPanel" noGutters>
          <Col md="auto" className={styles.tableContainer}>
            <SkyPulseTable
              title="Ship list"
              columns={columns}
              rows={filteredAircraftList}
              onRowClick={(id) => {
                const aircraft = rAircraftList.find((ac) => ac.id === id);
                onSelect(aircraft);
              }}
              defaultSort="tail"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ShipSelect.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

const state = (state) => {
  return ({
    rAircraftList: state.aircraftList,
  });
};

export default connect(state)(ShipSelect);
