import React from 'react';
import { Col, Row } from 'react-bootstrap';
import beveragesIcon from 'assets/icons/AIX/beverages.png';
import SkyPulseTable from 'components/SkyPulseTable';
import styles from './styles.module.css';

import appleJuice from 'assets/icons/AIX/beverages/applejuice.png';
import bacardiRum from 'assets/icons/AIX/beverages/bacardiRum.png';
import bloodymary from 'assets/icons/AIX/beverages/bloodymary.png';
import bombayGin from 'assets/icons/AIX/beverages/bombayGin.png';
import bourbonCreamLiqueur from 'assets/icons/AIX/beverages/bourbonCreamLiqueur.png';
import clubsoda from 'assets/icons/AIX/beverages/clubsoda.png';
import coke from 'assets/icons/AIX/beverages/coke.png';
import cokezero from 'assets/icons/AIX/beverages/cokezero.png';
import cranberryjuice from 'assets/icons/AIX/beverages/cranberryjuice.png';
import dewarsScotch from 'assets/icons/AIX/beverages/dewarsScotch.png';
import dietcoke from 'assets/icons/AIX/beverages/dietcoke.png';
import duNordVodka from 'assets/icons/AIX/beverages/duNordVodka.png';
import imageryWine from 'assets/icons/AIX/beverages/imageryWine.png';
import jackDaniellsWhisky from 'assets/icons/AIX/beverages/jackDaniellsWhisky.png';
import lunazulTequila from 'assets/icons/AIX/beverages/lunazulTequila.png';
import orangejuice from 'assets/icons/AIX/beverages/orangejuice.png';
import seagramsGingerAle from 'assets/icons/AIX/beverages/seagramsGingerAle.png';
import sprite from 'assets/icons/AIX/beverages/sprite.png';
import tipTopOldFashioned from 'assets/icons/AIX/beverages/tipTopOldFashioned.png';
import tomatojuice from 'assets/icons/AIX/beverages/tomatojuice.png';
import tonicwater from 'assets/icons/AIX/beverages/tonicwater.png';
import woodfordBourbon from 'assets/icons/AIX/beverages/woodfordBourbon.png';

const standardColumns = [
  {
    name: 'Item',
    key: 'item',
    size: 'xlarge',
  },
  {
    name: 'Initial',
    key: 'initial',
    size: 'small',
  },
  {
    name: 'Remaining',
    key: 'remaining',
    size: 'medium',
  },
];

const data = [
  {
    name: 'Coke',
    initial: 6,
    remaining: 5,
    img: coke,
    isCan: true,
  },
  {
    name: 'Zero Sugar Coke',
    initial: 2,
    remaining: 2,
    img: cokezero,
    isCan: true,
  },
  {
    name: 'Diet Coke',
    initial: 4,
    remaining: 3,
    img: dietcoke,
    isCan: true,
  },
  {
    name: 'Sprite',
    initial: 3,
    remaining: 1,
    img: sprite,
    isCan: true,
  },
  {
    name: 'Seagram’s Ginger Ale',
    initial: 5,
    remaining: 4,
    img: seagramsGingerAle,
    isCan: true,
  },
  {
    name: 'Tonic Water',
    initial: 8,
    remaining: 7,
    img: tonicwater,
    isCan: true,
  },
  {
    name: 'Orange Juice (carton)',
    initial: 1,
    remaining: 75,
    img: orangejuice,
    isCan: false,
  },
  {
    name: 'Cranberry Juice (carton)',
    initial: 1,
    remaining: 80,
    img: cranberryjuice,
    isCan: false,
  },
  {
    name: 'Apple Juice (carton)',
    initial: 1,
    remaining: 65,
    img: appleJuice,
    isCan: false,
  },
  {
    name: 'Bloody Mary Mix',
    initial: 2,
    remaining: 2,
    img: bloodymary,
    isCan: true,
  },
  {
    name: 'Club Soda',
    initial: 2,
    remaining: 1,
    img: clubsoda,
    isCan: true,
  },
  {
    name: 'Tomato Juice',
    initial: 2,
    remaining: 1,
    img: tomatojuice,
    isCan: true,
  },
  {
    name: 'Dewar’s Scotch',
    initial: 2,
    remaining: 1.5,
    img: dewarsScotch,
    isCan: true,
  },
  {
    name: 'Jack Daniell’s Whisky',
    initial: 3,
    remaining: 2,
    img: jackDaniellsWhisky,
    isCan: true,
  },
  {
    name: 'Du Nord Vodka',
    initial: 7,
    remaining: 6,
    img: duNordVodka,
    isCan: true,
  },
  {
    name: 'Lunazul Tequila',
    initial: 3,
    remaining: 2,
    img: lunazulTequila,
    isCan: true,
  },
  {
    name: 'Bombay Gin',
    initial: 2,
    remaining: 2,
    img: bombayGin,
    isCan: true,
  },
  {
    name: 'Bacardi Rum',
    initial: 2,
    remaining: 1,
    img: bacardiRum,
    isCan: true,
  },
  {
    name: 'Bourbon Cream Liqueur',
    initial: 2,
    remaining: 2,
    img: bourbonCreamLiqueur,
    isCan: true,
  },
  {
    name: 'Woodford Bourbon',
    initial: 6,
    remaining: 5,
    img: woodfordBourbon,
    isCan: true,
  },
  {
    name: 'Tip Top Old Fashioned',
    initial: 4,
    remaining: 3,
    img: tipTopOldFashioned,
    isCan: true,
  },
  {
    name: 'Imagery Wine',
    initial: 6,
    remaining: 5,
    img: imageryWine,
    isCan: true,
  },
];

const tableData = data.map((dt) => {
  const fillWidth = dt.isCan ? (dt.remaining * 100) / dt.initial : dt.remaining;
  return {
    item: (
      <div>
        <img src={dt.img} alt="" className={styles.bevIcon} />
        <span> {dt.name} </span>
      </div>
    ),
    initial: dt.initial,
    remaining: (
      <div className={styles.graphContainer}>
        <div className={styles.graphBase}>
          <div
            className={styles.fillContainer}
            style={{ width: `${fillWidth}%` }}
          />
        </div>
        <span className={styles.barText}> {dt.remaining} </span>
      </div>
    ),
  };
});

const data1 = tableData.splice(0, Math.ceil(tableData.length / 2));
const data2 = tableData;

const Beverages = () => {
  return (
    <Row noGutters className={styles.container}>
      <Col className={styles.iconContainer}>
        <span className={styles.title}> Beverages </span>
        <img src={beveragesIcon} alt="" className={styles.icon} />
      </Col>
      <Col className={styles.tableContainer}>
        <Row noGutters>
          <Col>
            <SkyPulseTable
              columns={standardColumns}
              rows={data1}
              tableClassname={styles.table}
              rowClassName={styles.tableRow}
            />
          </Col>
          <Col>
            <SkyPulseTable
              columns={standardColumns}
              rows={data2}
              tableClassname={styles.table}
              rowClassName={styles.tableRow}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Beverages;
