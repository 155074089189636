import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  PATHS,
} from 'constants';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'LRUFaultManifest';

const Software = (props) => {
  const {
    aircraftList,
    onFleetRowClick,
    push,
    reportsEnabled
  } = props;
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);

  const columnDefinitions = [
    {
      name: 'Tail',
      key: 'tail',
      sortable: true,
      size: 'xsmall',
    },
    {
      name: 'Fleet',
      key: 'fleet',
      sortable: true,
      size: 'small',
    },
    {
      name: 'SW manifest part',
      key: 'sw_manifest_part',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
    }];

  const onSelectChange = ({ target: { value } }) => {
    setFleetOption(value);
  }

  const data = !aircraftList
    ? []
    : aircraftList
      .filter((ac) => (fleetOption === SELECT_OPTION_ALL.value) || (ac.aircraft_type.id === fleetOption))
      .map((ac) => {
        return (
          {
            id: ac.id,
            tail: ac.tail,
            fleet: ac.aircraft_type.value.name,
            sw_manifest_part: ac.stats.software_part_number
          }
        );
      });

  return (
    <Row className={styles.root} noGutters>
      <Col>
        <Row className={styles.title} noGutters>
          <Col className="title_1">Fleet software manifest</Col>
          <Col md="auto">
            {
              (reportsEnabled ?
                (
                  <Button
                    className="button_reg"
                    data-cy-id="LRU-LRUFaultsReportButton" onClick={() => push(PATHS.reportLruFaults)}
                  >
                    LRUs & Faults reports
                  </Button>
                )
                : null
              )
            }
          </Col>
        </Row>
        <Row className={styles.fleetSelectionContainer} noGutters>
          <Col md="auto">
            <Select
              idPrefix={idPrefix}
              label="Fleet: "
              onChange={onSelectChange}
              options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
              value={fleetOption}
            />
          </Col>
        </Row>
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100">
            <SkyPulseTable
              title="Ship - Manifest list"
              columns={columnDefinitions}
              rows={data}
              defaultSort="sw_manifest_part"
              defaultSortState="descending"
              onRowClick={(id) => {
                onFleetRowClick(id)
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Software;
