import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Aircraft } from 'models';
import {
  EVENT_TYPE_RSSI,
  EVENT_TYPE_WAP_PRIMARY,
  EVENT_TYPE_WAP_SECONDARY,
  EVENT_TYPE_OFFLINE
} from 'constants';
import { sortAscendingByCreated } from 'utils';

import LOPA from 'components/LOPA';
import SkyPulseTable from 'components/SkyPulseTable';
import ButtonItem from 'components/inputs/ButtonItem';
import RSSIKeyImg from "assets/images/RSSIKey.png";

import { Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';

const idPrefix = 'heatMap';
const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  },
  {
    name: 'Average RSSI',
    key: 'avg_rssi',
  },
  {
    name: 'ITUs expected',
    key: 'itus_expected',
  },
  {
    name: 'ITUs actual',
    key: 'itus_actual',
  },
  {
    name: 'ITUs served',
    key: 'itus_served',
    size: 'small',
  },
];

const FlightHeatMap = (props) => {
  const { aircraft, push, flight } = props;
  const { aircraft_type: aircraftType, lopa: aircraftLopa } = aircraft;
  const { waps, passenger_displays: passengerDisplays } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);
  const [selectedLruId, setSelectedLruId] = useState(null);
  const [showRSSIOverlay, setShowRSSIOverlay] = useState(false);

  const onRowClick = (lruId, buttonId = null) => {
    if (
      selectedLruId === lruId &&
      (buttonId === null || buttonId === activeButtonId)
    ) {
      setActiveButtonId(null);
      setSelectedLruId(null);
    } else {
      setActiveButtonId(buttonId);
      setSelectedLruId(lruId);
    }
  }

  const lopaData = {
    passenger_displays: {}
  };

  passengerDisplays.forEach((pd) => {
    lopaData.passenger_displays[pd.id] = {
      selected: (!selectedLruId) ? true : false
    };
  });

  let allRSSITotal = 0;
  let totalITUs = 0;
  const data = waps.map(({
    id,
    primary_passenger_displays,
    secondary_passenger_displays,
    lru_type
  }) => {
    const selectedWap = selectedLruId === id;

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lru_type.id, id);

    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      'active': true,
      'selected': selectedWap,
    };
    let itusCount = 0;
    let rssiTotal = 0;

    // Assigning RSSI value to every seats.
    flight.events.sort(sortAscendingByCreated)
      .filter((event) => (
        event.event_type.id === EVENT_TYPE_RSSI.id
      ))
      .map((event) => {
        const rssiValue = event.extra.rssi;
        lopaData.passenger_displays[event.lru_position]['heatmap'] = rssiValue;
        return true;
      });

    // WAP based calculations
    primary_passenger_displays.forEach((pd) => {
      let rssiValue = 0;
      flight.events.sort(sortAscendingByCreated)
        .filter((event) => (event.lru_position === pd && (
          event.event_type.id === EVENT_TYPE_WAP_PRIMARY.id
          || event.event_type.id === EVENT_TYPE_WAP_SECONDARY.id
          || event.event_type.id === EVENT_TYPE_OFFLINE.id
          || event.event_type.id === EVENT_TYPE_RSSI.id
        )))
        .map((event) => {
          if (rssiValue !== 0) return true;
          let isConnected = true;
          switch (event.event_type.id) {
            case EVENT_TYPE_OFFLINE.id:
            case EVENT_TYPE_WAP_SECONDARY.id:
              isConnected = false
              break;
            case EVENT_TYPE_WAP_PRIMARY.id:
              isConnected = true;
              break;
            case EVENT_TYPE_RSSI.id:
              rssiValue = event.extra.rssi;
              if (isConnected) {
                itusCount++;
                rssiTotal += rssiValue;
              }
              break;
            default:
              break;
          }
          if (selectedWap) {
            lopaData.passenger_displays[pd]['selected'] = isConnected;
          }
          return true;
        });
      allRSSITotal += rssiValue;
    });

    secondary_passenger_displays.forEach((spd) => {
      let rssiValue = 0;
      flight.events.sort(sortAscendingByCreated)
        .filter((event) => (event.lru_position === spd && (
          event.event_type.id === EVENT_TYPE_WAP_PRIMARY.id
          || event.event_type.id === EVENT_TYPE_WAP_SECONDARY.id
          || event.event_type.id === EVENT_TYPE_OFFLINE.id
          || event.event_type.id === EVENT_TYPE_RSSI.id
        )))
        .map((event) => {
          if (rssiValue !== 0) return true;
          let isConnected = false;
          switch (event.event_type.id) {
            case EVENT_TYPE_OFFLINE.id:
            case EVENT_TYPE_WAP_PRIMARY.id:
              isConnected = false
              break;
            case EVENT_TYPE_WAP_SECONDARY.id:
              isConnected = true;
              break;
            case EVENT_TYPE_RSSI.id:
              rssiValue = event.extra.rssi;
              if (isConnected) {
                itusCount++;
                rssiTotal += rssiValue;
              }
              break;
            default:
              break;
          }
          if (selectedWap) {
            lopaData.passenger_displays[spd]['selected'] = isConnected;
          }
          return true;
        });
    });

    if (selectedWap && activeButtonId) {
      const pds = (activeButtonId === 'Primary') ? primary_passenger_displays : secondary_passenger_displays;
      pds.forEach((pd) => {
        lopaData.passenger_displays[pd]['associated'] = true;
      });
    }

    const buttons = ['Primary', 'Secondary'].map((display, i) => {
      return (
        <div
          key={i}
          className={classNames(
            "skypulsetable_toggle_button",
            (selectedWap && display === activeButtonId)
              ? "active"
              : null
          )}
          data-clickable-id={display}
        >
          {display}
        </div>
      )
    });

    totalITUs += itusCount;

    return (
      {
        id: id,
        itus_served: buttons,
        lru: lru_type.value,
        pos: id,
        avg_rssi: !rssiTotal ? 0 : (rssiTotal / itusCount).toFixed(1),
        itus_expected: primary_passenger_displays.length,
        itus_actual: itusCount,
      }
    );
  });
  const avgITURSSI = !allRSSITotal ? 0 : (allRSSITotal / totalITUs).toFixed(1);

  return (
    <div className={classNames(styles.flightWrapper, aircraftType.value.name)}>
      <LOPA
        mode="heatmap"
        data={lopaData}
        push={push}
        aircraft={aircraft}
        showRSSIValue={showRSSIOverlay}
      />
      <Row noGutters>
        <Col className={styles.leftContainer}>
          <Row>
            <Col className={styles.rssiTitle}>
              RSSI
            </Col>
          </Row>
          <Row noGutters>
            <img alt="" src={RSSIKeyImg} className={styles.key} />
          </Row>
        </Col>
        <Col>
          <Row className={styles.infoContainer} noGutters>
            <Col className={styles.info}>
              Average ITU RSSI ({totalITUs} ITU):&nbsp;
              <span className={styles.boldText}> {avgITURSSI} </span>
              <div className={styles.divider} />
              <div className={styles.rssiOverlayContainer}>
                LOPA:
                <ButtonItem
                  label="RSSI value overlay"
                  value="rssi_overlay"
                  onChange={() => {
                    setShowRSSIOverlay(!showRSSIOverlay);
                  }}
                  type="checkbox"
                  classname={classNames(styles.buttonItem, styles.bluetoothButtonItem)}
                  isChecked={showRSSIOverlay}
                  idPrefix={`${idPrefix}-rssiOverlay`}
                  data-cy-id={`${idPrefix}-rssiOverlay`}
                />
              </div>
            </Col>
            <Col md="auto" className={styles.note}>
              Note: First available values from this flight
              <br /> are shown for LOPA & WAP table.
            </Col>
          </Row>
          <Row className={styles.tableContainer} noGutters>
            <Col className="h-100">
              <SkyPulseTable
                title="WAP"
                columns={columns}
                rows={data}
                onRowClick={onRowClick}
                selectedId={selectedLruId}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

FlightHeatMap.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  flight: PropTypes.object,
  push: PropTypes.func,
}

export default FlightHeatMap;
