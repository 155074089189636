import React from 'react';
import PropTypes from 'prop-types';

import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  }, {
    name: 'HW part',
    key: 'hw_part',
    size: 'xsmall',
  }, {
    name: 'HW serial',
    key: 'hw_serial',
    size: 'xsmall',
  }, {
    name: 'FW version',
    key: 'fw_version',
    size: 'xsmall',
  }
];

const PublicDisplays = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let fw_version;
  let hw_part;
  let hw_serial;
  if (latestLRUHistory?.software_version) {
    const { software_version: { CFW, FUB, SWB } } = latestLRUHistory;
    fw_version = SWB;
    hw_part = FUB;
    hw_serial = CFW;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      hw_part: hw_part,
      hw_serial: hw_serial,
      fw_version: fw_version,
    }
  ];

  return (
    <Row className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={overviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PublicDisplays.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft)
}

export default PublicDisplays;
