import React from 'react';
import classnames from 'classnames';

import { PATHS, AIRCRAFT_STATUS_OFFLINE } from 'constants';
import { getAircraftHealthHistogramData, getUTCDateTime } from 'utils';

import { Button, Col, Row } from 'react-bootstrap';
import HealthGauge from 'components/HealthGauge';
import HealthHistogram from 'components/HealthHistogram';
import SkyPulseTable from 'components/SkyPulseTable';
import BarGraph from 'components/BarGraph';

import styles from './styles.module.css';

const columns =
  [
    {
      name: 'Departure UTC',
      key: 'utc',
      sortable: true,
      className: styles.utcColumn,
    },
    {
      name: 'Flight',
      key: 'number',
      sortable: true,
      className: styles.otherColumn,
    },
    {
      name: 'Depart',
      key: 'dep',
      sortable: true,
      className: styles.otherColumn,
    },
    {
      name: 'Arrive',
      key: 'des',
      sortable: true,
      className: styles.otherColumn,
    },
    {
      name: 'Status',
      key: 'status',
      sortable: true,
      className: styles.otherColumn,
    }
  ];

const ShipOverview = (props) => {
  const { aircraft, flights, push, clearActiveFlight, setActiveFlight, aircraftList } = props;
  const histogramData = getAircraftHealthHistogramData(aircraft.history);
  const filteredFlights = flights.filter((flight) => aircraft.flights.find(fl => fl.id === flight.id
  )).map((flight) => {
    return (
      {
        id: flight.id,
        utc: flight.departure_timestamp
          ? { display: getUTCDateTime(flight.departure_timestamp), sort: flight.departure_timestamp }
          : null,
        number: flight.number,
        dep: flight.departure_airport?.value.iata,
        des: flight.destination_airport?.value.iata,
        status: flight.is_open ? 'Open' : 'Closed',
      }
    );
  });

  const {
    tail,
    stats: currentAircraftStats
  } = aircraft;

  let fleetStats = {
    avgFaults: 0,
    avgResets: 0,
    avgWap: 0,
    avgDisconnected: 0
  }

  const filteredAircraft = aircraftList.filter((a) => a.aircraft_type === aircraft.aircraft_type && a.status !== AIRCRAFT_STATUS_OFFLINE);
  filteredAircraft.forEach((aircraft) => {
    fleetStats.avgFaults += aircraft.stats.fault_count_seven_day_average;
    fleetStats.avgResets += aircraft.stats.pax_reset_count_seven_day_average;
    fleetStats.avgWap += aircraft.stats.pax_secondary_wap_count_seven_day_average;
    fleetStats.avgDisconnected += aircraft.stats.pax_disconnected_count_seven_day_average;
  })
  fleetStats.avgFaults = Math.round(fleetStats.avgFaults / filteredAircraft.length);
  fleetStats.avgFaults = isNaN(fleetStats.avgFaults) ? 0 : fleetStats.avgFaults;

  fleetStats.avgResets = Math.round(fleetStats.avgResets / filteredAircraft.length);
  fleetStats.avgResets = isNaN(fleetStats.avgResets) ? 0 : fleetStats.avgResets;

  fleetStats.avgWap = Math.round(fleetStats.avgWap / filteredAircraft.length);
  fleetStats.avgWap = isNaN(fleetStats.avgWap) ? 0 : fleetStats.avgWap;

  fleetStats.avgDisconnected = Math.round(fleetStats.avgDisconnected / filteredAircraft.length);
  fleetStats.avgDisconnected = isNaN(fleetStats.avgDisconnected) ? 0 : fleetStats.avgDisconnected;

  currentAircraftStats.fault_count_seven_day_average = Math.round(currentAircraftStats.fault_count_seven_day_average);
  currentAircraftStats.fault_count_seven_day_average = isNaN(currentAircraftStats.fault_count_seven_day_average) ? 0 : currentAircraftStats.fault_count_seven_day_average;

  currentAircraftStats.pax_disconnected_count_seven_day_average = Math.round(currentAircraftStats.pax_disconnected_count_seven_day_average);
  currentAircraftStats.pax_disconnected_count_seven_day_average = isNaN(currentAircraftStats.pax_disconnected_count_seven_day_average) ? 0 : currentAircraftStats.pax_disconnected_count_seven_day_average;

  currentAircraftStats.pax_reset_count_seven_day_average = Math.round(currentAircraftStats.pax_reset_count_seven_day_average);
  currentAircraftStats.pax_reset_count_seven_day_average = isNaN(currentAircraftStats.pax_reset_count_seven_day_average) ? 0 : currentAircraftStats.pax_reset_count_seven_day_average;

  currentAircraftStats.pax_secondary_wap_count_seven_day_average = Math.round(currentAircraftStats.pax_secondary_wap_count_seven_day_average);
  currentAircraftStats.pax_secondary_wap_count_seven_day_average = isNaN(currentAircraftStats.pax_secondary_wap_count_seven_day_average) ? 0 : currentAircraftStats.pax_secondary_wap_count_seven_day_average;

  return (
    <Row className={styles.root} data-cy-id="Ship-OverviewPanel" noGutters>
      <Col className="h-100">
        <Row className={styles.healthRow} noGutters>
          <Col className={styles.block}>
            <Row noGutters>
              <Col md="auto" className="title_2">Current</Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col md="auto">
                <HealthGauge healthScore={aircraft.health_score} />
              </Col>
            </Row>
          </Col>
          <Col md="auto" style={{ width: '20px' }} />
          <Col className={styles.block}>
            <Row noGutters>
              <Col md="auto" className="title_2">7 day</Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col md="auto">
                <HealthHistogram histogramData={histogramData} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col md="auto" style={{ height: '20px' }} />
        <Row className={styles.healthRow} noGutters>
          <Col className={styles.block}>
            <Row noGutters>
              <div>
                <h3 md="auto" className="title_2">Average faults</h3>
                <h3 md="auto" className="title_4">Last 7 days</h3>
              </div>
            </Row>
            <Row className="align-items-center justify-content-center flex-gow-1 mt-5" noGutters>
              <Col>
                <BarGraph
                  title={tail}
                  primaryFillColor={fleetStats.avgFaults >= currentAircraftStats.fault_count_seven_day_average
                    ? "var(--CL_GREEN_3)" : "var(--CL_STATUS_DEGRADED)"
                  }
                  primaryFillPercent={currentAircraftStats.fault_count_seven_day_average > fleetStats.avgFaults
                    ? 100
                    : ((currentAircraftStats.fault_count_seven_day_average * 100) / fleetStats.avgFaults)
                  }
                  primaryLabel={currentAircraftStats.fault_count_seven_day_average}
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col>
                <BarGraph
                  title="Fleet"
                  primaryFillPercent={currentAircraftStats.fault_count_seven_day_average > fleetStats.avgFaults
                    ? ((fleetStats.avgFaults * 100) / currentAircraftStats.fault_count_seven_day_average)
                    : 100
                  }
                  primaryLabel={fleetStats.avgFaults}
                  primaryFillColor="var(--CL_GRAY_230)"
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="auto" style={{ width: '20px' }} />
          <Col className={styles.block}>
            <Row noGutters>
              <div>
                <h3 md="auto" className="title_2">Average resets</h3>
                <h3 md="auto" className="title_4">Last 7 days</h3>
              </div>
            </Row>
            <Row className="align-items-center justify-content-center flex-gow-1 mt-5" noGutters>
              <Col>
                <BarGraph
                  title={tail}
                  primaryFillColor={fleetStats.avgResets >= currentAircraftStats.pax_reset_count_seven_day_average
                    ? "var(--CL_GREEN_3)" : "var(--CL_STATUS_DEGRADED)"
                  }
                  primaryFillPercent={currentAircraftStats.pax_reset_count_seven_day_average > fleetStats.avgResets
                    ? 100
                    : ((currentAircraftStats.pax_reset_count_seven_day_average * 100) / fleetStats.avgResets)
                  }
                  primaryLabel={currentAircraftStats.pax_reset_count_seven_day_average}
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col>
                <BarGraph
                  title="Fleet"
                  primaryFillPercent={currentAircraftStats.pax_reset_count_seven_day_average > fleetStats.avgResets
                    ? ((fleetStats.avgResets * 100) / currentAircraftStats.pax_reset_count_seven_day_average)
                    : 100
                  }
                  primaryLabel={fleetStats.avgResets}
                  primaryFillColor="var(--CL_GRAY_230)"
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col md="auto" style={{ height: '20px' }} />
        <Row className={styles.healthRow} noGutters>
          <Col className={styles.block}>
            <Row noGutters>
              <div>
                <h3 md="auto" className="title_2">ITUs using secondary WAP (at least once during flight)</h3>
                <h3 md="auto" className="title_4">Last 7 days average</h3>
              </div>
            </Row>
            <Row className="align-items-center justify-content-center flex-gow-1 mt-5" noGutters>
              <Col>
                <BarGraph
                  title={tail}
                  primaryFillColor={fleetStats.avgWap >= currentAircraftStats.pax_secondary_wap_count_seven_day_average
                    ? "var(--CL_GREEN_3)" : "var(--CL_STATUS_DEGRADED)"
                  }
                  primaryFillPercent={currentAircraftStats.pax_secondary_wap_count_seven_day_average > fleetStats.avgWap
                    ? 100
                    : ((currentAircraftStats.pax_secondary_wap_count_seven_day_average * 100) / fleetStats.avgWap)
                  }
                  primaryLabel={currentAircraftStats.pax_secondary_wap_count_seven_day_average}
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col>
                <BarGraph
                  title="Fleet"
                  primaryFillPercent={currentAircraftStats.pax_secondary_wap_count_seven_day_average > fleetStats.avgWap
                    ? ((fleetStats.avgWap * 100) / currentAircraftStats.pax_secondary_wap_count_seven_day_average)
                    : 100
                  }
                  primaryLabel={fleetStats.avgWap}
                  primaryFillColor="var(--CL_GRAY_230)"
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="auto" style={{ width: '20px' }} />
          <Col className={styles.block}>
            <Row noGutters>
              <div>
                <h3 md="auto" className="title_2">ITUs disconnected from network (at least once during flight)</h3>
                <h3 md="auto" className="title_4">Last 7 days average</h3>
              </div>
            </Row>
            <Row className="align-items-center justify-content-center flex-gow-1 mt-5" noGutters>
              <Col>
                <BarGraph
                  title={tail}
                  primaryFillColor={fleetStats.avgDisconnected >= currentAircraftStats.pax_disconnected_count_seven_day_average
                    ? "var(--CL_GREEN_3)" : "var(--CL_STATUS_DEGRADED)"
                  }
                  primaryFillPercent={currentAircraftStats.pax_disconnected_count_seven_day_average > fleetStats.avgDisconnected
                    ? 100
                    : ((currentAircraftStats.pax_disconnected_count_seven_day_average * 100) / fleetStats.avgDisconnected)
                  }
                  primaryLabel={currentAircraftStats.pax_disconnected_count_seven_day_average}
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center flex-grow-1" noGutters>
              <Col>
                <BarGraph
                  title="Fleet"
                  primaryFillPercent={currentAircraftStats.pax_disconnected_count_seven_day_average > fleetStats.avgDisconnected
                    ? ((fleetStats.avgDisconnected * 100) / currentAircraftStats.pax_disconnected_count_seven_day_average)
                    : 100
                  }
                  primaryLabel={fleetStats.avgDisconnected}
                  primaryFillColor="var(--CL_GRAY_230)"
                  style={{
                    height: '40px',
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md="auto" style={{ width: '20px' }} />
      <Col className={classnames("h-100", styles.tableContainer)} data-cy-id="Ship-OverviewPanel-FlightTable">
        <SkyPulseTable
          title={
            <Row>
              <Col>Flights - Last 7 days</Col>
              <Col md="auto">
                <Button
                  className="button_reg"
                  onClick={() => {
                    clearActiveFlight();
                    push(PATHS.flights);
                  }}
                >
                  See all flights
                </Button>
              </Col>
            </Row>
          }
          columns={columns}
          rows={filteredFlights}
          defaultSort="utc"
          defaultSortState="descending"
          onRowClick={(id) => {
            const flight = flights.find(flight => flight.id === id);
            setActiveFlight(flight);
            push(PATHS.flights);
          }}
        />
      </Col>
    </Row>
  );

}

export default ShipOverview;
