import React from 'react';
import PropTypes from 'prop-types';

import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  }, {
    name: 'Type',
    key: 'type',
    size: 'xsmall',
  }, {
    name: 'FW version',
    key: 'fw_version',
    size: 'xsmall',
  }]

const PCUs = (props) => {
  const { lruData, aircraft } = props;

  const pcuPos = lruData.position;
  const latestLRUHistory = lruData.getLatestHistory();
  let fw_version;
  if (latestLRUHistory?.software_version) {
    const { software_version: { SWB } } = latestLRUHistory;
    fw_version = SWB;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: pcuPos,
      type: lruData?.lru_type.value,
      fw_version: fw_version,
    }
  ];

  return (
    <Row className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={overviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PCUs.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
}

export default PCUs;
