import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Row,
  Col,
} from 'react-bootstrap';
import styles from './styles.module.css';

const BarGraph = (props) => {
  const {
    title,
    primaryFillColor,
    secondaryFillColor,
    primaryLabel,
    secondaryLabel,
    primaryFillPercent,
    secondaryFillPercent,
    primaryLabelClassName,
    secondaryLabelClassName,
    containerClassName,
    transparentBaseColor,
  } = props;

  return (
    <Row className={styles.root}>
      <Col md={4}>
        {title}
      </Col>
      <Col md={5} className={classnames(containerClassName, styles.barContainer, transparentBaseColor ? styles.transparentBarContainer : null)}>
        <div
          style={{ width: `${primaryFillPercent}%`, backgroundColor: primaryFillColor }}
          className={styles.barFill}
        />
        {
          secondaryFillColor
            ? (
              <div
                style={{ width: `${secondaryFillPercent}%`, backgroundColor: secondaryFillColor }}
                className={classnames(styles.barFill, styles.secondaryFill)}
              />
            ) : null
        }
      </Col>
      <Col className={styles.label}>
        <span className={primaryLabelClassName}> {primaryLabel} </span>
        {
          secondaryLabel === null || secondaryLabel === undefined || secondaryLabel === ""
            ? null
            : (
              <>
                |
                <span className={secondaryLabelClassName}> {secondaryLabel} </span>
              </>
            )
        }
      </Col>
    </Row>
  );
}

BarGraph.propTypes = {
  title: PropTypes.string,
  primaryFillColor: PropTypes.string,
  secondaryFillColor: PropTypes.string,
  primaryFillPercent: PropTypes.number,
  secondaryFillPercent: PropTypes.number,
  primaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryLabelClassName: PropTypes.string,
  secondaryLabelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  transparentBaseColor: PropTypes.bool,
};

export default BarGraph;
