import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import classnames from 'classnames';

import { setActiveAircraft, setActiveFlight } from 'actions';

import { PATHS } from 'constants';
import { getFlightHeaderText, getLopaBodyType, evaluatePermissions } from 'utils';

import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import LeftNav from 'components/default/LeftNav';
import FlightOverview from 'components/FlightOverview';
import FlightUsage from './FlightUsage';
import FlightSelect from 'components/default/FlightSelect';
import FlightHeatMap from 'components/FlightHeatMap';

import General from './General';
import Beverages from './Beverages';

import styles from './styles.module.css';

const Flight = (props) => {
  const {
    goBack,
    push,
    rActiveAircraft,
    rActiveFlight,
    rConfiguration,
    rFlightList,
    rUser,
    setActiveAircraft,
    setActiveFlight,
    match: { path },
  } = props;

  const [showFlightSelect, setShowFlightSelect] = useState(false);
  const reportsEnabled = rConfiguration.ff_reports;
  const rssiHeatmapEnabled = rConfiguration.ff_rssi_heatmap;
  const pathParams = path?.split('/');
  const activeMenuItem = pathParams[2] ? pathParams[2] : 'overview';

  // temporary override
  let usageAllowed = evaluatePermissions(rUser, ['Marketing']);
  usageAllowed = true;

  const orderedFlightList = !rFlightList || !rActiveAircraft || !rActiveFlight
    ? []
    : rFlightList
      .filter((flight) => { return flight.aircraft_id === rActiveAircraft.id })
      .sort((a, b) => { return a.departure_timestamp - b.departure_timestamp });

  const flightIndex = !orderedFlightList.length
    ? 0
    : orderedFlightList.map(f => f.id).indexOf(rActiveFlight.id);

  const renderMenuItem = () => {
    switch (activeMenuItem) {
      case 'general':
        return <General />;
      case 'bevTotals':
        return <Beverages />;
      case 'usage':
        if (!usageAllowed) {
          return renderOverview();
        }
        return <FlightUsage
          key={rActiveFlight.id}
          aircraft={rActiveAircraft}
          flight={rActiveFlight}
          push={push}
        />;
      case 'heatmap':
        return <FlightHeatMap
          aircraft={rActiveAircraft}
          flight={rActiveFlight}
          push={push}
        />;
      case 'overview':
      default:
        return renderOverview();
    }
  };

  const renderOverview = () => {
    return <FlightOverview
      aircraft={rActiveAircraft}
      flight={rActiveFlight}
      push={push}
    />;
  };

  if (!rActiveAircraft || !rActiveFlight || showFlightSelect) {
    return (
      <FlightSelect onCancel={() => {
        if (rActiveFlight) {
          setShowFlightSelect(false);
        } else {
          goBack();
        }
      }} onSelect={(flight, aircraft) => {
        setActiveAircraft(aircraft);
        setActiveFlight(flight);
        setShowFlightSelect(false);
      }} />
    );
  }

  if (rActiveFlight?.aircraft_id !== rActiveAircraft?.id) {
    console.error('Active aircraft is not same as active flight aircraft.');
  }

  const aircraftTypeName = (rActiveAircraft.aircraft_type) ? rActiveAircraft.aircraft_type.value.name : '';
  const NavFlightHeader = `Flight ${rActiveFlight.number}`;

  const NavMap = {
    [NavFlightHeader]: [
      {
        display: 'Overview',
        id: 'overview',
      },
      ...usageAllowed
        ? [{
          display: 'Usage',
          id: 'usage',
        }] : [],
      {
        display: 'Heat map',
        id: 'heatmap',
        hide: !rssiHeatmapEnabled,
      },
    ],
    'Passenger Service': [
      {
        display: 'General',
        id: 'general',
      },
      {
        display: 'Bev totals',
        id: 'bevTotals',
      },
      {
        display: 'Bev cart 1',
        id: 'bevCart1',
      },
    ],
  };

  const goBackward = () => {
    setActiveFlight(orderedFlightList[flightIndex - 1]);
  };

  const goForward = () => {
    setActiveFlight(orderedFlightList[flightIndex + 1]);
  };

  const goBackwardDisabled = flightIndex <= 0;
  const gotForwardDisabled = flightIndex >= orderedFlightList.length - 1;

  return (
    <Row className={classnames(styles.root, getLopaBodyType(aircraftTypeName))} data-cy-id="Flights" noGutters>
      <Col lg="auto" className={styles.leftPanel}>
        <LeftNav
          activeMenuItem={activeMenuItem}
          changeActiveMenu={(id) => push(`${PATHS.flights}/${id}`)}
          navMap={NavMap}
          primaryHeader={
            <div>
              Tail&nbsp;
              <Button
                data-cy-id="Flights-LeftNav-TailLink"
                variant="link"
                className={styles.openAircraftButton}
                onClick={() => { push(PATHS.ship) }}
              >
                {rActiveAircraft.tail}
              </Button>
            </div>
          }
          secondaryHeader={aircraftTypeName}
          testIdPrefix="Flights"
        />
      </Col>
      <Col className={styles.container}>
        <Row className={styles.headerContainer} noGutters>
          <Col className={styles.header}>
            {getFlightHeaderText(rActiveFlight)}
            {
              activeMenuItem === 'general' ||
                activeMenuItem === 'bevTotals' ||
                activeMenuItem === 'bevCart1' ? (
                  <span>
                    |
                    <span className={styles.blueFont}> Real-time status </span>
                  </span>
                ) : null
            }
          </Col>
          {!rActiveFlight.is_open && reportsEnabled &&
            !(
              activeMenuItem === 'general' ||
              activeMenuItem === 'bevTotals' ||
              activeMenuItem === 'bevCart1'
            ) ? (
              activeMenuItem === 'overview' ? (
                <Col md="auto">
                  <Button
                    className="button_reg"
                    data-cy-id="Flight-FlightReportButton"
                    onClick={() => push(`${PATHS.reportFlight}/${rActiveFlight.id}`)}
                  >
                    Flight reports
                  </Button>
                </Col>
              ) : (
                  <Col md="auto">
                    <Button
                      className="button_reg"
                      data-cy-id="Flight-FlightReportButton"
                      onClick={() =>
                        push(`${PATHS.reportUsage}/${rActiveFlight.id}`)
                      }
                    >
                      Usage reports
                  </Button>
                  </Col>
                )
            )
            : null
          }
          <Col md="auto">
            <Button
              className={`button_foc ${styles.headerButton}`}
              data-cy-id="Flights-ChangeFlightButton"
              onClick={() => setShowFlightSelect(true)}
            >
              Change flight
            </Button>
          </Col>
          <Col md="auto">
            <Button
              className={`button_foc ${styles.iconButton}`}
              onClick={goBackward}
              disabled={goBackwardDisabled}
            >
              &lt;
            </Button>
          </Col>
          <Col md="auto">
            <Button
              className={`button_foc ${styles.iconButton}`}
              onClick={goForward}
              disabled={gotForwardDisabled}
            >
              &gt;
            </Button>
          </Col>
        </Row>
        {renderMenuItem()}
      </Col>
    </Row>
  );
};

const state = (state) => {
  return ({
    rActiveAircraft: state.activeAircraft,
    rActiveFlight: state.activeFlight,
    rConfiguration: state.configuration,
    rFlightList: state.flightList,
    rUser: state.user,
  });
};

const actions = {
  setActiveAircraft,
  setActiveFlight,
  push,
  goBack,
};

export default connect(state, actions)(Flight);
