import React from 'react';
import PropTypes from 'prop-types';

import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
  }, {
    name: 'SW part',
    key: 'sw_part',
    size: 'small',
  }, {
    name: 'Config module',
    key: 'config_module',
    size: 'small',
  },
];

const PowerSupplies = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let config_module;
  let sw_part;
  if (latestLRUHistory?.software_version) {
    const { software_version: { SWCFG, SWPN } } = latestLRUHistory;
    config_module = SWCFG;
    sw_part = SWPN;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      sw_part: sw_part,
      config_module: config_module,
    }
  ]

  return (
    <Row className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={overviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PowerSupplies.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft)
}

export default PowerSupplies;
