import { LOPA } from 'models';

export function getAircraftConfigStringValue(value) {
  const str = [];
  Object.keys(value).map((key) => {
    if (value[key]) {
      switch (key) {
        case 'J':
          str.push('Delta One');
          break;
        case 'P':
          str.push('Premium Select');
          break;
        case 'F':
          str.push('First');
          break;
        case 'W':
          str.push('Comfort +');
          break;
        case 'Y':
          str.push('Main');
          break;
        default:
          break;
      }
    }
    return 0;
  });
  return str.length > 0 ? str.join(', ') : 'None';
}

export function getUniqueLRUPositions(aircraftTypes, lruTypeId) {
  const lopaTypes = LOPA.getLRUDeviceMap()[lruTypeId];
  const posArr = [];
  aircraftTypes.map((at) => {
    lopaTypes.map((lt) => {
      at.value.lopa[lt]
        .map((type) => {
          const isDuplicate = posArr.find((p) => p === type.id);
          if (!isDuplicate) {
            posArr.push(type.id)
          }
          return false;
        })
      return false;
    })
    return false;
  });
  return posArr;
}
