import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import {
  PATHS,
  AIRCRAFT_CONFIGURATION_TYPE_BLUETOOTH_ZONES,
  AIRCRAFT_CONFIGURATION_TYPE_DISABLED_USB,
  AIRCRAFT_CONFIGURATION_TYPE_DISMISSIBLE_BILLBOARDS,
} from 'constants';
import {
  getUTCDateTime,
  getAircraftConfigStringValue,
} from 'utils';
import styles from './styles.module.css';

const columns = [
  {
    name: 'Item',
    key: 'name',
    size: 'large',
  },
  {
    name: 'Value',
    key: 'value',
    size: 'large',
  },
  {
    name: 'Last changed',
    key: 'lastChanged',
    size: 'small',
  },
];

const Configuration = (props) => {
  const { push, aircraft } = props;

  const data = [];
  aircraft?.configuration.forEach((config) => {
    const latestHistory = config.getLatestHistory();
    let value = null;
    let created = null;
    let name = null;
    switch (config.configuration_type) {
      case AIRCRAFT_CONFIGURATION_TYPE_BLUETOOTH_ZONES:
        value = latestHistory ? getAircraftConfigStringValue(latestHistory.value) : 'N/A';
        created = latestHistory?.created;
        name = `${config.configuration_type.value.name} enabled`;
        break;
      case AIRCRAFT_CONFIGURATION_TYPE_DISABLED_USB:
        value = latestHistory
          ? latestHistory.value.length > 0 ? latestHistory.value.sort().join(', ') : 'None'
          : 'N/A';
        created = latestHistory?.created;
        name = config.configuration_type.value.name;
        break;
      case AIRCRAFT_CONFIGURATION_TYPE_DISMISSIBLE_BILLBOARDS:
      default:
        value = latestHistory.value;
        created = latestHistory.created;
        name = config.configuration_type.value.name;
        break;
    }
    data.push({
      id: config.configuration_type.id,
      name: name,
      value,
      lastChanged: `${getUTCDateTime(created)} UTC`,
    })
  })

  return (
    <Row className={styles.root} data-cy-id="Ship-ContentPanel" noGutters>
      <Col md={12}>
        <Row className={styles.tableRow} noGutters>
          <Col md={12}>
            <Row className={styles.tableHeaderRow} noGutters>
              <Col className={styles.tableContainer}>
                <SkyPulseTable
                  columns={columns}
                  rows={data}
                  title={"Configurable features"}
                  onRowClick={(id) => {
                    push(`${PATHS.configurationHistory}/${id}`);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default Configuration;
