import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  LRU_TYPE_ITU_10,
  LRU_TYPE_ITU_13,
  LRU_TYPE_ITU_18,
  LRU_TYPE_OVD,
} from 'constants';
import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const ovdColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
  },
  {
    name: 'HW part',
    key: 'hw_part',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'HW serial',
    key: 'hw_serial',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'FW version',
    key: 'fw_version',
    showOutliers: true,
  },
];

const ituColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
  },
  {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'ITU ISD',
    key: 'itu_isd',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
    showOutliers: true,
  },
  {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    size: 'small',
    showOutliers: true,
  },
  {
    name: 'MCU',
    key: 'mcu',
    size: 'xsmall',
  }
];

const PublicDisplays = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { public_displays: publicDisplays, passenger_displays: passengerDisplays } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);

  const localOnRowClick = (lruId, buttonId = null) => {
    setActiveButtonId(buttonId);
    onRowClick(lruId);
  }

  const mcuButtonId = 'mcu';

  const selectedLOPA = publicDisplays.find((pd) => pd.id === selectedLru);
  const selectedLOPAITUMCUID = selectedLOPA?.mcu;
  const selectedLOPAITUMCUChannel = selectedLOPA?.mcu_channel;

  const lopaData = {
    passenger_displays: {}
  };
  const pdTableDataMap = {};
  publicDisplays.forEach((pd) => {
    const { id, lru_type: lruType } = pd
    const pdTypeName = lruType.value;
    const lru = aircraft.getLRUByTypePosition(pdTypeName, id);

    if (!pdTableDataMap[pdTypeName]) {
      pdTableDataMap[pdTypeName] = [];
    }

    const isITUlruType = lruType === LRU_TYPE_ITU_10
      || lruType === LRU_TYPE_ITU_13 || lruType === LRU_TYPE_ITU_18;
    if (isITUlruType) {
      const { mcu: lopaMCUID, mcu_channel: lopaMCUChannel } = pd;
      const selected = selectedLru === id;

      let itu_isd;
      let itu_fss;
      let itu_rom;
      let map_assets_sw_part;
      let map_sw_part;

      if (lru?.software_version) {
        const { FSS, ISD, MAP, MASA, ROM, } = lru.software_version;
        itu_fss = FSS;
        itu_isd = ISD;
        itu_rom = ROM;
        map_assets_sw_part = MASA;
        map_sw_part = MAP;
      }

      const mcuButton = (<div
        className={classnames(
          "skypulsetable_toggle_button",
          (selected && activeButtonId === mcuButtonId)
            ? "active"
            : null
        )}
        data-clickable-id={mcuButtonId}
      >
        {`MCU ${lopaMCUID}-J${lopaMCUChannel}`}
      </div>);

      pdTableDataMap[pdTypeName].push({
        itu_fss,
        itu_isd,
        itu_rom,
        lru: lruType.value,
        map_assets_sw_part,
        map_sw_part,
        mcu: mcuButton,
        pos: id,
        id: id
      });

    } else {
      let fw_version;
      let hw_part;
      let hw_serial;
      if (lru?.software_version) {
        const { software_version: { CFW, FUB, SWB } } = lru;
        fw_version = SWB;
        hw_part = FUB;
        hw_serial = CFW;
      }
      pdTableDataMap[pdTypeName].push({
        fw_version,
        id: id,
        lru: pdTypeName,
        pos: id,
        hw_part,
        hw_serial,
      });
    }

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(lruType.id, id);
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      'active': true,
      'selected': selectedLru === id
    };
  });

  passengerDisplays.map((pd) => {
    const { id, mcu: lopaMCUID, mcu_channel: lopaMCUChannel } = pd;
    const selected = selectedLru === id;

    let associated = false;
    if (activeButtonId && activeButtonId === mcuButtonId) {
      associated = (lopaMCUID === selectedLOPAITUMCUID && lopaMCUChannel === selectedLOPAITUMCUChannel);
    }

    lopaData.passenger_displays[id] = {
      'active': true,
      'selected': selected,
      'associated': associated
    };
    return true;
  });

  const tableMaxHeight = `calc(100%/${Object.keys(pdTableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA
          mode="config"
          data={lopaData}
          aircraft={aircraft}
          push={push}
        />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            {
              Object.keys(pdTableDataMap).map((key, index) => {
                const tData = pdTableDataMap[key];
                const isITUlruType = tData[0].lru !== LRU_TYPE_OVD.value;
                return (
                  <Row
                    key={index}
                    className={styles.multiTable}
                    style={{ maxHeight: tableMaxHeight }}
                    noGutters
                  >
                    <Col className="h-100">
                      <SkyPulseTable
                        title={key}
                        columns={isITUlruType ? ituColumns : ovdColumns}
                        rows={tData}
                        onRowClick={localOnRowClick}
                        selectedId={selectedLru}
                      />
                    </Col>
                  </Row>
                );
              })
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PublicDisplays.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
}

export default PublicDisplays;
