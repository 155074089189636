import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import iconHeadsetDark from "assets/icons/iconHeadsetDark.png";
import {
  PAX_USAGE_TYPES,
  LRU_TYPE_ITU_10,
  LRU_TYPE_ITU_13,
  LRU_TYPE_ITU_18,
} from 'constants';
import { Aircraft } from 'models';
import { getHeatMapColor, getLopaBodyType } from 'utils';

import { Col, Row } from 'react-bootstrap';
import LruOverlay from './LruOverlay';
import LRUDetail from 'view/components/overlays/LRUDetail';

import styles from './styles.module.css';
import classNames from 'classnames';

const getValue = (obj, mode, isLatest) => {
  switch (mode) {
    case 'seatReset':
      return obj && obj['resets'] ? obj['resets'] : false;
    case 'wap':
      return obj && obj['wap'] ?
        isLatest ? (obj['wap']['secondary'] || false)
          : true : false;
    case 'disconnected':
      return obj && obj['disconnected'] ?
        isLatest ? (obj['disconnected']['offline'] || false)
          : true : false;
    case 'config':
    case 'heatmap':
      return obj;
    default:
      return obj && obj[mode] ? obj[mode] : false;
  }
}

const Lru = (props) => {
  const {
    handleClick,
    deviceId,
    deviceType,
    mode,
    lruType,
    value,
    bluetoothInUse,
    showRSSIValue
  } = props;

  let style = styles.usageUnknown;
  let display = '';
  let heatMapColor = null;

  switch (mode) {
    case 'faults':
      style = value === 1
        ? styles.singleFaultStatus
        : value > 1
          ? styles.multipleFaultStatus
          : null;

      display = value > 1
        ? value
        : '';
      break;

    case 'seatReset':
      let resetCount = 0;
      style = null;

      if (value) {
        const {
          affected,
          hard,
          soft,
        } = value;

        if (hard) {
          resetCount += hard;
          style = styles.seatResetHard;
        }

        if (soft) {
          resetCount += soft;
          if (!style) style = styles.seatResetSoft;
        }

        if (resetCount > 1) display = resetCount;

        if (affected) {
          if (!style) style = styles.seatResetAffected;
          if (display === '') display = <span className={styles.seatResetAffectedDot} />;
        }
      }
      break;

    case 'wap':
      style = value
        ? styles.wapStatus
        : null;
      break;

    case 'disconnected':
      style = value
        ? styles.disconnectedStatus
        : null;
      break;

    case 'usage':
      switch (value) {
        case PAX_USAGE_TYPES.getEnumerationItem('Map').id:
          style = styles.usageMap;
          break;
        case PAX_USAGE_TYPES.getEnumerationItem('Video').id:
          style = styles.usageVideo;
          break;
        case PAX_USAGE_TYPES.getEnumerationItem('Audio').id:
          style = styles.usageAudio;
          break;
        case PAX_USAGE_TYPES.getEnumerationItem('Live TV').id:
          style = styles.usageLiveTV;
          break;
        case PAX_USAGE_TYPES.getEnumerationItem('Games').id:
          style = styles.usageGame;
          break;
        case PAX_USAGE_TYPES.getEnumerationItem('Idle').id:
          style = styles.usageIdle;
          break;
        default:
          style = styles.usageUnknown;
          break;
      }
      display = !bluetoothInUse ? "" : <img alt="" src={iconHeadsetDark} className={styles.bluetoothIcon} />
      break;

    // TODO: Change the values based on heatmap options
    case 'heatmap': {
      const { active, associated, selected, heatmap } = value;
      const isPaggengerlruType = lruType === LRU_TYPE_ITU_10.value || lruType === LRU_TYPE_ITU_13.value || lruType === LRU_TYPE_ITU_18.value;
      heatMapColor = (isPaggengerlruType && selected) ? getHeatMapColor(heatmap) : null;
      style = classnames(
        active
          ? styles.usageIdle
          : styles.usageUnknown,
        selected && !isPaggengerlruType
          ? styles.configSelected
          : null,
        associated
          ? styles.heatMapAssociated
          : null
      );
      display = deviceType === 'passenger_displays' && selected && showRSSIValue && !isNaN(heatmap)
        ? <span className={styles.heatMapDisplay}> {heatmap} </span>
        : '';
      break;
    }
    case 'config': {
      const { active, associated, selected } = value;
      style = classnames(
        active
          ? styles.usageIdle
          : styles.usageUnknown,
        selected
          ? styles.configSelected
          : associated
            ? styles.configAssociated
            : null
      );
      break;
    }
    default:
      style = styles.usageUnknown;
      break;
  }

  const heatMapStyle = !heatMapColor ? null : { backgroundColor: `rgb(${heatMapColor[0]}, ${heatMapColor[1]}, ${heatMapColor[2]})` };
  return (
    <Col
      data-cy-class="LOPA_LRU"
      data-cy-id={`LOPA-LRU-${lruType}-${deviceId}`}
      style={heatMapStyle}
      className={classnames(styles.lru, style)}
      id={deviceId}
      onClick={(e) => { if (handleClick) { handleClick(lruType, deviceType, e); } }}
    >
      {display}
    </Col>
  )
}

const Aisle = () => {
  return (<Col data-cy-class="LOPA_aisle" className={classnames(styles.lru, styles.aisle)} />);
}

const BlankSeat = () => {
  return (<Col data-cy-class="LOPA_blank_seat" className={classnames(styles.lru, styles.blankSeatContainer)} />);
}

const LOPA = (props) => {
  const {
    data,
    mode,
    push,
    aircraft,
    isLatest,
    showRSSIValue
  } = props;

  const [overlayProps, setOverlayProps] = useState({ isOpen: false });
  const [lruDetailOverlayProps, setLRUDetailOverlayProps] = useState({ id: null, isOpen: false });
  const aircraftLOPA = aircraft.lopa;
  const { classes } = aircraftLOPA;
  const { headendMap, seatMap } = (aircraftLOPA) ? aircraftLOPA.getConfiguration() : [];

  const getAverage = (a, b) => (a + b) / 2;
  const aircraftBodyType = getLopaBodyType(aircraft.aircraft_type.value.name);

  const handleClickLru = (lruType, deviceType, { target }) => {
    const { innerWidth } = window;
    const { bottom, left, right, top } = target.getBoundingClientRect();
    const position = { x: getAverage(left, right), y: getAverage(top, bottom) };
    const id = target.tagName !== 'DIV' ? target.parentElement.id : target.id;
    setOverlayProps({ position, lruType: { type: lruType, pos: id }, deviceType, isOpen: true, rightCorner: position.x > innerWidth / 2 });
  }

  return (
    <>
      <Row
        data-cy-class="LOPA"
        className={classNames(
          "mx-0",
          styles.lopa,
          aircraftBodyType)}
      >
        <Col className={styles.lruMap} data-cy-class="LOPA_headEndContainer">
          {
            headendMap.map((row, i) => {
              const { devices, short_name: shortDeviceName } = row;
              if (devices.length < 1) return null;
              return (
                <Row className={styles.lruRow} key={i} data-cy-class="LOPA_LRURow">
                  <Col md={4} data-cy-class="LOPA_LRURowLabel">
                    {shortDeviceName}
                  </Col>
                  {
                    devices.map((device, i) => {
                      const { id: deviceId, lru_type: { value: lruType, id: lruTypeId } } = device;
                      const deviceType = aircraftLOPA.getDeviceTypeByLRUTypePosition(lruTypeId, deviceId);
                      const value = getValue((data[deviceType] && data[deviceType][deviceId])
                        ? data[deviceType][deviceId]
                        : false, mode, isLatest);
                      return <Lru
                        key={i}
                        handleClick={handleClickLru}
                        deviceId={deviceId}
                        deviceType={deviceType}
                        mode={mode}
                        lruType={lruType}
                        value={value}
                      />
                    })
                  }
                </Row>
              )
            })
          }
        </Col>
        <Col className={classnames(styles.seatMap)} data-cy-class="LOPA_seatMapContainer">
          <div className="h-100 d-flex">
            {
              seatMap.map((row, i) => {
                if (row === null) {
                  return (
                    <Row key={i} className={classNames("h-100", styles.divider)} />
                  )
                } else {
                  const { row: rowNo, seats } = row;
                  return (
                    <Col
                      key={`row-${rowNo}`}
                      className={styles.seatRow}
                      data-cy-class="LOPA_LRURow"
                    >
                      <Row className={styles.labelContainer}>
                        <Col className={styles.rowLabel} data-cy-class="LOPA_LRURowLabel">
                          {rowNo}
                        </Col>
                      </Row>
                      {
                        seats.map((position, idx) => {
                          if (position === null) {
                            return (
                              <Row key={idx}>
                                <Aisle />
                              </Row>
                            );
                          }

                          if (position?.blank) {
                            return (
                              <Row key={idx} className={styles.seatContainer}>
                                <BlankSeat />
                              </Row>
                            );
                          }

                          const { passenger_display: device } = position;
                          const { id: posId, lru_type: { value: lruTypeName, id: lruTypeId }, class: cls } = device;
                          const classNameSafe = classes.find(({ id }) => id === cls).name.replace(/\W/g, '');
                          const deviceType = aircraftLOPA.getDeviceTypeByLRUTypePosition(lruTypeId, posId);
                          const value = getValue((data[deviceType] && data[deviceType][posId])
                            ? data[deviceType][posId]
                            : false, mode, isLatest);
                          const bluetoothInUse = data[deviceType] && data[deviceType][posId] ? data[deviceType][posId].bluetoothInUse : false;
                          return (
                            <Row key={idx}
                              className={classNames(styles.seatContainer, classNameSafe.toLowerCase(), aircraftBodyType)}>
                              <Lru
                                handleClick={handleClickLru}
                                deviceId={posId}
                                deviceType={deviceType}
                                mode={mode}
                                lruType={lruTypeName}
                                value={value}
                                bluetoothInUse={bluetoothInUse}
                                showRSSIValue={showRSSIValue}
                              />
                            </Row>
                          )
                        })
                      }
                    </Col>
                  )
                }
              })
            }
          </div>
        </Col>
      </Row>
      <LRUDetail
        {...lruDetailOverlayProps}
        onClose={() => setLRUDetailOverlayProps({ id: null, isOpen: false })}
      />
      <LruOverlay
        {...overlayProps}
        onClose={() => setOverlayProps({ isOpen: false })}
        push={push}
        handleDetailClick={(lruType) => {
          const detail = aircraft.getLRUByTypePosition(lruType.type, lruType.pos);
          setLRUDetailOverlayProps({ id: detail.id, isOpen: true });
        }}
      />
    </>
  );
}

LOPA.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  data: PropTypes.object,
  mode: PropTypes.string.isRequired,
  push: PropTypes.func,
  isLatest: PropTypes.bool,
  showRSSIValue: PropTypes.bool,
}

export default LOPA;
