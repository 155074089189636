import React, { useState, useEffect } from 'react';
import {
  AIRCRAFT_TYPE_A220100,
  AIRCRAFT_TYPE_A220300,
  DEFAULT_OPTION,
} from 'constants';

import {
  a220LogListGET,
  a220LogsPOST,
} from 'libs/api';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import ButtonItem from 'components/inputs/ButtonItem';
import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'A220Logs';

const A220Logs = (props) => {
  const {
    aircraftList,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  // log list filters
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [selectedAircraftId, setSelectedAircraftId] = useState('');
  const [logfileDt, setLogfileDt] = useState(null);

  // logfile lists
  const [availableLogfiles, setAvailableLogfiles] = useState([]);
  const [selectedLogfileMap, setSelectedLogfileMap] = useState({});

  const columnDefinitions = [
    {
      key: 'select',
      size: 'tiny',
      onToggle: (e) => {
        const { checked } = e.target;
        const logfileMap = Object.assign({}, selectedLogfileMap);
        availableLogfiles.forEach(({ filename }) => {
          logfileMap[filename] = checked;
        });
        setSelectedLogfileMap(logfileMap);
      }
    },
    {
      name: 'Filename',
      key: 'filename',
      size: 'large',
      sortable: true,
    },
  ];

  useEffect(() => {
    const filteredOptions = [DEFAULT_OPTION].concat(
      !aircraftList.length ? [] : aircraftList.filter((aircraft) => {
        return (
          aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220100.id
          || aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220300.id
        );
      }).map((aircraft) => {
        return {
          value: aircraft.id,
          name: `${aircraft.tail} (${aircraft.registration || 'NA'})`,
        };
      })).sort((a, b) => a.name.localeCompare(b.name));
    setAircraftOptions(filteredOptions);
  }, [aircraftList]);

  useEffect(() => {
    if (!Object.keys(selectedLogfileMap).length) {
      return;
    }

    setAvailableLogfiles(
      Object.entries(selectedLogfileMap).map(([logfile, selected]) => {
        return {
          select: (
            <ButtonItem
              name=""
              type="checkbox"
              id={logfile}
              data-clickable-id={logfile}
              isChecked={selected}
            />
          ),
          filename: logfile,
        };
      }),
    );
  }, [selectedLogfileMap]);

  const hasSelectedLogs = () => {
    return Object.values(selectedLogfileMap).some((item) => item);
  }

  const handleLogFetch = async () => {
    try {
      setIsLoading(true);
      const response = await a220LogListGET(selectedAircraftId, logfileDt / 1000).catch((e) => {
        setIsLoading(false);
      });

      const {
        data: {
          result: {
            logs: availableLogfiles,
          },
        },
      } = response;

      const logfileMap = {};
      availableLogfiles.forEach((filename) => {
        logfileMap[filename] = false;
      });

      setSelectedLogfileMap(logfileMap);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogDownload = async () => {
    try {
      setIsLoading(true);
      const selectedLogfiles = Object.entries(selectedLogfileMap).filter(([key, selected]) => {
        return selected;
      }).map(([key, _selected]) => {
        return key;
      });

      const response = await a220LogsPOST(
        selectedAircraftId,
        logfileDt / 1000,
        selectedLogfiles,
      ).catch(() => {
        setIsLoading(false);
      });

      const {
        data: {
          result: secureUrl,
        },
      } = response;

      const anchor = document.createElement('a');
      anchor.setAttribute('href', secureUrl);
      anchor.setAttribute('target', '_blank');
      anchor.click();
    } catch (e) {
      console.warn(e);
    } finally {
      // cleanup
      setIsLoading(false);
      setSelectedAircraftId('');
      setLogfileDt(null);
      setAvailableLogfiles([]);
      setSelectedLogfileMap({});
    }
  };

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col className="title_1">A220 Logs</Col>
            <Col md="auto" className={styles.note}>
              Note: logs can be fetched on a per-day basis and downloaded in batches
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id={`${idPrefix}-Filters`} noGutters>
            <Col>
              <Row className={styles.filterContainer}>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Tail`}
                    label="Tail: "
                    options={aircraftOptions}
                    value={selectedAircraftId}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setSelectedAircraftId(value);
                    }}
                  />
                </Col>
                <Col md="auto">
                  <DatePicker
                    idPrefix={`${idPrefix}-Date`}
                    label="Logfile Date: "
                    value={logfileDt}
                    onChange={(date) => {
                      setLogfileDt(parseInt(date, 10));
                    }}
                    maxDate={Date.now()}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-LoadLogsButton`}
                disabled={isLoading || !selectedAircraftId || !logfileDt}
                onClick={handleLogFetch}
              >
                Fetch Available Logs
              </Button>
            </Col>
          </Row>
          <Row className={styles.dataContainer} noGutters>
            <Col>
              <SkyPulseTable
                columns={columnDefinitions}
                rows={availableLogfiles}
                isLoading={isLoading}
                showHeaders={true}
                onRowClick={(id) => {
                  if (id === 'root') { return; }
                  const logfileMap = Object.assign({}, selectedLogfileMap);
                  logfileMap[id] = !logfileMap[id];
                  setSelectedLogfileMap(logfileMap);
                }}
                className={styles.logTable}
              />
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-DownloadLogsButton`}
                disabled={isLoading || !hasSelectedLogs()}
                onClick={handleLogDownload}
              >
                Download Selected Logs
              </Button>
            </Col>
          </Row>
        </Col >
      </Row >
    </>
  );
};

export default A220Logs;
